import React, { useEffect, useState } from "react";
import { CircularProgress, Typography, Box, Select, MenuItem, IconButton, Tooltip, ToggleButtonGroup, ToggleButton } from "@mui/material";
import ZoomInIcon from "@mui/icons-material/ZoomIn";
import ZoomOutIcon from "@mui/icons-material/ZoomOut";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import SchemaIcon from "@mui/icons-material/Schema";
import DashboardIcon from "@mui/icons-material/Dashboard";
import FlowGraph from "./reactFlow/FlowGraph";
import Hierarchy from "./Hierarchy";
import MermaidMainFlow from "./mermaid/MermaidMainFlow";

interface FlowViewerProps {
    data: any;
}
const FlowViewer: React.FC<FlowViewerProps> = ({ data }) => {
    const [mainFlows, setMainFlows] = useState<any[]>([]);
    const [subFlows, setSubFlows] = useState<any[]>([]);
    const [currentFlow, setCurrentFlow] = useState<any>(null);
    const [loading, setLoading] = useState(true);
    const [selectedMainFlowId, setSelectedMainFlowId] = useState<number | null>(null);
    const [flowHistory, setFlowHistory] = useState<any[]>([]);
    const [scale, setScale] = useState<number>(1); // State to manage scale
    const [showSubFlows, setShowSubFlows] = useState<boolean>(false); // Toggle for sub-flows
    const [viewMode, setViewMode] = useState<"FlowGraph" | "Mermaid">("FlowGraph"); // Toggle between views

    useEffect(() => {
        // Reinitialize state whenever data changes
        const initializeFlow = () => {
            setLoading(true);
            setMainFlows([]);
            setSubFlows([]);
            setFlowHistory([]);
            setSelectedMainFlowId(null);
            setCurrentFlow(null);

            if (data.mainFlows && data.mainFlows.length > 0) {
                setMainFlows(data.mainFlows);
                setSubFlows(data.subFlows || []);
                const firstFlow = data.mainFlows[0];
                setSelectedMainFlowId(firstFlow.id);
                setCurrentFlow(firstFlow);
            }

            setLoading(false);
        };

        initializeFlow();
    }, [data]);

    if (loading) {
        return <CircularProgress />;
    }

    if (!currentFlow) {
        return <Typography>No flow data available.</Typography>;
    }

    return (
        <Box sx={{ height: "95vh", width: "100%", display: "flex", flexDirection: "column" }}>
            {/* Graph Section */}
            <Box sx={{ flex: 1, display: "flex", flexDirection: "column", padding: 2 }}>
                <Box>
                    <Hierarchy
                        flowHistory={flowHistory}
                        setFlowHistory={setFlowHistory}
                        currentFlow={currentFlow}
                        resetToMainFlow={() => {
                            setFlowHistory([]);
                            setCurrentFlow(mainFlows.find((flow) => flow.id === selectedMainFlowId));
                        }}
                    />
                </Box>

                {/* Conditionally Render FlowGraph or MermaidMainFlow */}
                {viewMode === "FlowGraph" ? (
                    <FlowGraph
                        currentFlow={currentFlow}
                        subFlows={showSubFlows ? subFlows : []}
                        setCurrentFlow={setCurrentFlow}
                        setFlowHistory={setFlowHistory}
                    />
                ) : (
                    <MermaidMainFlow mainFlows={mainFlows} />
                )}
            </Box>

            {/* Roller Menu and Controls */}
            <Box
                sx={{
                    width: "100%",
                    padding: 2,
                    borderTop: "1px solid lightgray",
                    position: "sticky",
                    bottom: 0,
                    backgroundColor: "white",
                }}
            >
                <Typography variant="body2">Navigation Menu</Typography>
                <Select
                    onChange={(e) => {
                        const selectedFlow = mainFlows.find((flow) => flow.id === Number(e.target.value));
                        if (selectedFlow) setCurrentFlow(selectedFlow);
                    }}
                    style={{ width: "100%", padding: "8px", borderRadius: "4px", marginBottom: "16px" }}
                >
                    {mainFlows.map((flow) => (
                        <MenuItem key={flow.id} value={flow.id}>
                            {flow.header}
                        </MenuItem>
                    ))}
                </Select>
                {/* Zoom and Flow Display Controls */}
                <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", gap: 2 }}>
                    {/* Zoom Controls */}
                    <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                        <Tooltip title="Zoom Out">
                            <IconButton onClick={() => setScale((prev) => Math.max(prev - 0.1, 0.5))}>
                                <ZoomOutIcon />
                            </IconButton>
                        </Tooltip>
                        <Typography>{Math.round(scale * 100)}%</Typography>
                        <Tooltip title="Zoom In">
                            <IconButton onClick={() => setScale((prev) => Math.min(prev + 0.1, 2))}>
                                <ZoomInIcon />
                            </IconButton>
                        </Tooltip>
                    </Box>

                    {/* Toggle Buttons */}
                    <ToggleButtonGroup
                        value={showSubFlows}
                        exclusive
                        onChange={(_, newValue) => setShowSubFlows(newValue)}
                    >
                        <Tooltip title="Show Main Flow">
                            <ToggleButton value={false}>
                                <VisibilityOffIcon />
                            </ToggleButton>
                        </Tooltip>
                        <Tooltip title="Show Main + Sub-Flows">
                            <ToggleButton value={true}>
                                <VisibilityIcon />
                            </ToggleButton>
                        </Tooltip>
                    </ToggleButtonGroup>
                    <ToggleButtonGroup
                        value={viewMode}
                        exclusive
                        onChange={(_, newMode) => setViewMode(newMode)}
                    >
                        <Tooltip title="Diagram View">
                            <ToggleButton value="Mermaid">
                                <SchemaIcon />
                            </ToggleButton>
                        </Tooltip>
                        <Tooltip title="Interactive View">
                            <ToggleButton value="FlowGraph">
                                <DashboardIcon />
                            </ToggleButton>
                        </Tooltip>
                    </ToggleButtonGroup>
                </Box>
            </Box>
        </Box>
    );
};

export default FlowViewer;
