import { Node, Edge } from "reactflow";

const nodeWidth = 200;
const nodeHeight = 50;

// Define a smaller size for sub-flows
const subFlowNodeWidth = 150; // Reduced width
const subFlowNodeHeight = 40; // Reduced height

const colorPalette = [
    "#FF7F50", "#FFD700", "#90EE90", "#87CEEB",
    "#BA55D3", "#FFA07A", "#FF6347", "#40E0D0",
    "#FFDAB9", "#98FB98",
];

const nodeStyles = {
    border: "2px solid #007bff",
    borderRadius: "8px",
    padding: "10px",
    backgroundColor: "#ffffff",
    boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
    fontSize: "14px",
    textAlign: "center" as "center",
};

const subFlowNodeStyles = {
    ...nodeStyles,
    fontSize: "12px", // Smaller font for sub-flow nodes
    padding: "8px",
    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)", // Subtle shadow for sub-flow nodes
};

export const parseSubFlow = (
    subFlow: string,
    parentId: string,
    colorIndex: number,
    parentPosition: { x: number; y: number }
): { nodes: Node[]; edges: Edge[] } => {
    const lines = subFlow.split("\n").filter(Boolean);
    const nodes: Node[] = [];
    const edges: Edge[] = [];
    const subFlowColor = colorPalette[colorIndex % colorPalette.length];
    let yOffset = parentPosition.y + 100; // Reduced spacing between nodes

    // Handle the first two nodes
    if (lines.length > 1) {
        const firstLineMatch = lines[1].match(/(\w+)\[(.+?)\] --> (\w+)\[(.+?)\]/);
        if (firstLineMatch) {
            const [_, nodeIdA, labelA, nodeIdB, labelB] = firstLineMatch;

            // Add node A
            nodes.push({
                id: `${parentId}-${nodeIdA}`,
                data: { label: labelA },
                position: { x: parentPosition.x, y: yOffset },
                style: { ...subFlowNodeStyles, borderColor: subFlowColor },
            });

            yOffset += subFlowNodeHeight + 40; // Adjusted spacing for sub-flow

            // Add node B
            nodes.push({
                id: `${parentId}-${nodeIdB}`,
                data: { label: labelB },
                position: { x: parentPosition.x, y: yOffset },
                style: { ...subFlowNodeStyles, borderColor: subFlowColor },
            });

            // Add edge A --> B
            edges.push({
                id: `${parentId}-${nodeIdA}-${nodeIdB}`,
                source: `${parentId}-${nodeIdA}`,
                target: `${parentId}-${nodeIdB}`,
                animated: true,
                style: { stroke: subFlowColor, strokeWidth: 1.5 }, // Thinner edge for sub-flow
            });

            yOffset += subFlowNodeHeight + 40;
        }
    }

    // Process the remaining lines
    for (let i = 2; i < lines.length; i++) {
        const line = lines[i];

        const nodeMatch = line.match(/(\w+)\[(.+?)\]/);
        if (nodeMatch) {
            const [_, nodeId, label] = nodeMatch;

            nodes.push({
                id: `${parentId}-${nodeId}`,
                data: { label },
                position: { x: parentPosition.x, y: yOffset },
                style: { ...subFlowNodeStyles, borderColor: subFlowColor },
            });

            yOffset += subFlowNodeHeight + 40; // Adjusted spacing for sub-flow
        }

        const edgeMatch = line.match(/(\w+) --> (\w+)/);
        if (edgeMatch) {
            const [_, source, target] = edgeMatch;

            edges.push({
                id: `${parentId}-${source}-${target}`,
                source: `${parentId}-${source}`,
                target: `${parentId}-${target}`,
                animated: true,
                style: { stroke: subFlowColor, strokeWidth: 1.5 }, // Thinner edge for sub-flow
            });
        }
    }

    return { nodes, edges };
};
