import React, { useEffect, useRef, useState } from 'react';
import ReactPlayer from 'react-player';
import { Box, CircularProgress } from '@mui/material';
import PlayerControls from './PlayerControls';
import { useDispatch } from 'react-redux';
import { setSelectedVideoDetails } from '../../../features/coursesSlice';
import { setCurrentlyPlayingSubVideo } from '../../../features/PlayerTocSlice';
import { AppDispatch } from '../../../store';
import { getVideoFilesWithoutRange, getFileMp4FileSize } from '../../../api/serverApis/filesApi';
import { GATEWAY_API_URL } from "../../../api/api";
import { cleanTheDot } from '../../searchComponents/UtilityFunctions';
import Background from 'react-flow-renderer';
import CloseIcon from '@mui/icons-material/Close';
import { Rnd } from 'react-rnd';
interface ReactStreamingComponentProp {
  videoUrl: string;
  startTime?: string;
  frameTime?: string;
  endTime?: string;
  onTakeTest: (quizData: any) => void;
  movieList: any[];
  videoData?: any;
  onVideoComplete?: (videoId: string, subVideoId: string) => void;
}

const ReactStreamingComponent: React.FC<ReactStreamingComponentProp> = ({
  videoUrl,
  startTime,
  frameTime,
  endTime,
  onTakeTest,
  videoData,
  movieList,
  onVideoComplete,
}) => {
  const playerRef = useRef<ReactPlayer>(null);
  const [ready, setReady] = useState(false);
  const [playing, setPlaying] = useState(true); // Start playing by default
  const [loading, setLoading] = useState(true);
  const [duration, setDuration] = useState(0);
  const [playedSeconds, setPlayedSeconds] = useState(0);
  const [controlsVisible, setControlsVisible] = useState(true);
  const [playbackRate, setPlaybackRate] = useState(1);
  const [muted, setMuted] = useState(false);
  const [volume, setVolume] = useState(0.8);
  const [startTimeCurrent, setStartTimeCurrent] = useState(startTime || "0");
  const [endTimeCurrent, setEndTimeCurrent] = useState(endTime || "0");
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null);
  const [hoveredTime, setHoveredTime] = useState<number | null>(null);
  const [blockEnd, setBlockEnd] = useState(false);
  const [m3u8Url, setM3u8Url] = useState<string | null>(null);
  const dispatch = useDispatch<AppDispatch>();
  const containerRef = useRef<HTMLDivElement>(null);
  const [isFullscreen, setIsFullscreen] = useState(false);
  const [isFloating, setIsFloating] = useState(false);
  const [playerSize, setPlayerSize] = useState({ width: 400, height: 300 });
  const [position, setPosition] = useState({ x: 50, y: 50 });
  const [rewindSeconds, setRewindSeconds] = useState(-10); // Default rewind 10 seconds
  const [skipSeconds, setSkipSeconds] = useState(10);
  const getStartTime = () => {
    if (startTime && startTime !== "-1") {
      return cleanTheDot(startTime);
    } else if (frameTime) {
      return cleanTheDot(frameTime);
    } else {
      return "0";
    }
  };


  const getEndTime = () => {
    if (endTime && endTime !== "-1") {
      return cleanTheDot(endTime);
    } else {
      return duration.toString();
    }
  };
  const startInSeconds = parseInt(getStartTime(), 10);
  const endInSeconds = parseInt(getEndTime(), 10);
  // useEffect(() => {

  //   setBlockEnd(true);
  //   setLoading(true);
  //   setPlaying(true);
  //   setStartTimeCurrent(getStartTime());
  //   setEndTimeCurrent(getEndTime());

  //   const start = getStartTime();
  //   const end = getEndTime();
  //   setStartTimeCurrent(start);
  //   setEndTimeCurrent(end);
  //   setPlayedSeconds(parseInt(start, 10));
  //   if (videoData) {
  //     dispatch(setCurrentlyPlayingSubVideo({ movieId: videoData.movieId, subVideoId: videoData.id }));
  //   }
  //   const seekToStartTime = async () => {
  //     const start = getStartTime();
  //   await new Promise((resolve) => setTimeout(resolve, 500));
  //   playerRef.current?.seekTo(parseInt(start, 10));
  //   setLoading(false);
  //   setPlaying(true);
  //   setBlockEnd(false);
  // };

  // if (ready && playerRef.current && start) {
  //     seekToStartTime();
  //     setPlayedSeconds(parseInt(start, 10));
  //     setLoading(false);
  //     setPlaying(true);

  //   }
  //   fetchM3u8File();
  // }, [videoUrl,videoData, startTime, endTime, frameTime, ready]);

  useEffect(() => {
    if (videoData) {
      dispatch(setCurrentlyPlayingSubVideo({ movieId: videoData.movieId, subVideoId: videoData.id }));
    }
    setLoading(true);
    setPlaying(false);
    fetchM3u8File();  // Only fetch m3u8 when videoData or videoUrl changes

  }, [videoData, videoUrl]);
  useEffect(() => {
    let playbackRetryTimer: NodeJS.Timeout;

    const attemptPlayback = () => {
      const start = getStartTime();
      if (playerRef.current) {
        playerRef.current.seekTo(parseInt(start, 10));
      }
      setPlayedSeconds(parseInt(start, 10));
      setLoading(false);

      if (!playing) {
        // Retry setting `playing` to true after a small delay if it's not playing yet
        playbackRetryTimer = setTimeout(() => {
          setPlaying(true);
        }, 500);
      }
    };

    if (m3u8Url && ready) {
      attemptPlayback();
    }

    // Clean up the timer when the component unmounts or when dependencies change
    return () => clearTimeout(playbackRetryTimer);
  }, [m3u8Url, ready]);

  const fetchM3u8File = async () => {
    try {
      const response = await getVideoFilesWithoutRange(videoUrl);
      if (response.status === 200 && response.data.tempUrl) {
        const fullUrl = response.data.tempUrl;
        const tokenMatch = fullUrl.match(/token=([^&]*)/);
        const pathMatch = fullUrl.match(/&path=([^&]*)/);
        const token = tokenMatch ? tokenMatch[1] : null;
        const encodedPath = pathMatch ? pathMatch[1] : null;

        if (token && encodedPath) {
          const urlM3u8 = `${GATEWAY_API_URL}/m3u8-stream?token=${token}&path=${encodedPath}`;
          setM3u8Url(urlM3u8);
          setBlockEnd(true);
          const start = getStartTime();
          const end = getEndTime();
          setStartTimeCurrent(start);
          setEndTimeCurrent(end);
          setPlaying(true);
        }

      }
    } catch (error) {
      console.error("Error fetching m3u8 file:", error);
    }
  };

  useEffect(() => {
    // Add event listener for 'keydown' to listen for the ESC key
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'Escape' && isFullscreen) {
        setIsFullscreen(false); // Exit full screen when ESC is pressed
      }
    };

    window.addEventListener('keydown', handleKeyDown);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [isFullscreen]);

  const handleProgress = (state: { playedSeconds: number }) => {
    const currentTime = state.playedSeconds;

    if (currentTime < startInSeconds) {
      playerRef.current?.seekTo(startInSeconds);
      return;
    }

    setPlayedSeconds(currentTime);
    dispatch(setCurrentlyPlayingSubVideo({ movieId: videoData.movieId, subVideoId: videoData.id }));

    if (currentTime >= endInSeconds && !blockEnd) {
      setPlaying(false);
      if (onVideoComplete && videoData) {
        onVideoComplete(videoData.movieId, videoData.id);
      }
      handleNextVideo();
    }
  };

  const handleNextVideo = () => {
    const movieIndex = movieList.findIndex(movie => movie.movieId === videoData.movieId && movie.id === videoData.id);
    if (movieIndex !== -1) {
      const nextMovie = movieList[movieIndex + 1];
      if (nextMovie) {
        dispatch(setSelectedVideoDetails({
          movieId: nextMovie.movieId,
          id: nextMovie.id,
          courseId: nextMovie.courseId || ""
        }));
        setPlaying(true);
      }
    }
  };

  const handlePlayerReady = () => {
    setPlaying(true);
    setLoading(false);
  };

  const handlePlayPause = () => setPlaying(!playing);

  const handleSeekChange = (event: Event, newValue: number | number[]) => {
    const newTime = Array.isArray(newValue) ? newValue[0] : newValue;
    playerRef.current?.seekTo(newTime);
    setPlayedSeconds(newTime);
  };

  const handleVolumeChange = (event: Event, newValue: number | number[]) => {
    const newVolume = Array.isArray(newValue) ? newValue[0] : newValue;
    setVolume(newVolume / 100);
    setMuted(newVolume === 0);
  };

  const handleMute = () => {
    setMuted(!muted);
    setVolume(muted ? volume : 0);
  };

  const handlePlaybackRateChange = (rate: number) => {
    setPlaybackRate(rate);
    setAnchorEl(null);
  };

  const handleSliderHover = (event: React.MouseEvent<HTMLDivElement>) => {
    const sliderWidth = event.currentTarget.clientWidth;
    const offsetX = event.nativeEvent.offsetX;
    const hoverTime = startInSeconds + ((offsetX / sliderWidth) * (endInSeconds - startInSeconds));
    setHoveredTime(hoverTime);
  };

  const handleSliderLeave = () => setHoveredTime(null);

  const handleMenuClick = (event: React.MouseEvent<HTMLElement>) => setAnchorEl(event.currentTarget);
  const handleMenuClose = () => setAnchorEl(null);
  const handleMovieMenuClick = (event: React.MouseEvent<HTMLElement>) => setMenuAnchorEl(event.currentTarget);
  const handleMovieMenuClose = () => setMenuAnchorEl(null);

  const handleTakeTest = () => {
    setPlaying(false);
    onTakeTest({
      articleId: videoData.movieId,
      topicNumber: videoData.id
    });
  };
  const handlePreviousVideo = () => {
    const movieIndex = movieList.findIndex(movie => movie.movieId === videoData.movieId && movie.id === videoData.id);
    if (movieIndex > 0) {
      const prevMovie = movieList[movieIndex - 1];
      if (prevMovie) {
        dispatch(setSelectedVideoDetails({
          movieId: prevMovie.movieId,
          id: prevMovie.id,
          courseId: prevMovie.courseId || ""
        }));
        setPlaying(true);
      }
    }
  };
  const handleMovieSelect = (movie: any) => {
    dispatch(setSelectedVideoDetails({
      movieId: movie.movieId,
      id: movie.id,
      courseId: movie.courseId || ""
    }));
    setMenuAnchorEl(null);
  };
  const handleFullscreenToggle = () => {
    setIsFullscreen((prev) => !prev);
  };
  const handleToggleFloating = () => {
    setIsFloating(!isFloating);
  };
  const handleSeek = (offset: number) => {
    if (playerRef.current) {
      const currentTime = playerRef.current.getCurrentTime();
      const newTime = Math.max(0, currentTime + offset); // Ensure time doesn't go below 0
      playerRef.current.seekTo(newTime);
      setPlayedSeconds(newTime); // Update state
    }
  };

  const renderFloatingPlayer = () => (
    <Rnd
      size={{ width: playerSize.width, height: playerSize.height }}
      position={{ x: position.x, y: position.y }}
      onDragStop={(e, d) => setPosition({ x: d.x, y: d.y })}
      onResizeStop={(e, direction, ref, delta, pos) => {
        setPlayerSize({
          width: parseInt(ref.style.width, 10),
          height: parseInt(ref.style.height, 10),
        });
        setPosition(pos);
      }}
      bounds="window"
      style={{
        zIndex: 2147483,
        position: 'fixed', // Ensure it's always on top
      }}
      onMouseMove={() => setControlsVisible(true)}
      onMouseLeave={() => setControlsVisible(false)}
    >
      <Box
        sx={{
          zIndex: 999999,
          position: 'absolute',
          width: '100%',
          height: '100%',
          backgroundColor: 'black',
          borderRadius: '8px',
          overflow: 'hidden',
          boxShadow: '0 4px 10px rgba(0,0,0,0.2)',
        }}
      >
        {loading && (
          <Box
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              zIndex: 1,
            }}
          >
            <CircularProgress />
          </Box>
        )}
        <ReactPlayer
          style={{ zIndex: 999999999 }}
          ref={playerRef}
          url={m3u8Url || undefined}
          playing={playing} // Prevents playing while snackbar is open
          controls={false}
          width="100%"
          height="100%"
          onReady={() => setReady(true)}
          onProgress={handleProgress}
          playbackRate={playbackRate}
          muted={muted}
          volume={volume}
          onDuration={(duration) => setDuration(duration)}
          onPause={() => setPlaying(false)}
        />
        <Box
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '80%',
            backgroundColor: 'transparent',
            zIndex: 2,
            pointerEvents: 'auto',// Blocks interaction with underlying player
            cursor: 'pointer'
          }}
          onClick={(e) => {
            // e.stopPropagation();
            // if (playerRef.current && ready) {
            //   setPlaying(!playing); // Toggle play/pause
            //   if (playing) {
            //     playerRef.current?.getInternalPlayer().pauseVideo();
            //   } else {
            //     playerRef.current?.getInternalPlayer().playVideo();
            //   }
            // }
            e.stopPropagation();
            handlePlayPause();
          }}
        />
        {controlsVisible && (
          <PlayerControls
            startTimeCurrent={startInSeconds.toString()}
            endTimeCurrent={endInSeconds.toString()}
            playing={playing}
            playedSeconds={playedSeconds}
            duration={duration}
            volume={volume}
            muted={muted}
            playbackRate={playbackRate}
            anchorEl={anchorEl}
            menuAnchorEl={menuAnchorEl}
            hoveredTime={hoveredTime}
            handlePlayPause={handlePlayPause}
            handleVolumeClick={handleMute}
            handleVolumeChange={handleVolumeChange}
            handleSeekChange={handleSeekChange}
            handleSliderHover={handleSliderHover}
            handleSliderLeave={handleSliderLeave}
            handleMenuClick={handleMenuClick}
            handleMenuClose={handleMenuClose}
            handlePlaybackRateChange={handlePlaybackRateChange}
            handleMovieMenuClick={handleMovieMenuClick}
            handleMovieMenuClose={handleMovieMenuClose}
            handleNextVideo={handleNextVideo}
            handlePreviousVideo={handlePreviousVideo}
            handleTakeTest={handleTakeTest}
            handleMovieSelect={handleMovieSelect}
            movieList={movieList}
            handleFullscreenToggle={handleFullscreenToggle}
            isFullscreen={isFullscreen}
            handleToggleFloating={handleToggleFloating}
            isFloating={isFloating}
            handleSeek={handleSeek}
            setRewindSeconds={setRewindSeconds}
            setSkipSeconds={setSkipSeconds}
            rewindSeconds={rewindSeconds}
            skipSeconds={skipSeconds}
          />
        )}
      </Box>
    </Rnd>
  );

  const renderFullPlayer = () => (

    <Box
      ref={containerRef}
      sx={{
        position: isFullscreen ? 'fixed' : 'relative', // Fixed positioning in full screen
        top: isFullscreen ? 0 : 'unset',
        left: isFullscreen ? 0 : 'unset',
        width: isFullscreen ? '100vw' : '100%',
        height: isFullscreen ? '100vh' : '585px',
        backgroundColor: isFullscreen ? 'white' : 'transparent', // White background in full screen
        zIndex: isFullscreen ? 2000 : 1, // Bring to the top in full screen
        overflow: 'hidden',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
      onMouseMove={() => setControlsVisible(true)}
      onMouseLeave={() => setControlsVisible(false)}
    >
      {loading && (
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            zIndex: 1,
          }}
        >
          <CircularProgress />
        </Box>
      )}
      {isFullscreen && (
        <Box
          sx={{
            position: 'absolute',
            top: '16px',
            right: '16px',
            zIndex: 3, // Ensure it's above other elements
            cursor: 'pointer',
            background: 'rgba(0, 0, 0, 0.5)', // Semi-transparent background
            borderRadius: '50%',
            color: 'white',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '32px',
            height: '32px',
            transition: 'background 0.3s ease', // Smooth transition for hover effect
            '&:hover': {
              background: 'rgba(255, 0, 0, 0.8)', // Change background to red on hover
              transform: 'scale(1.1)', // Slightly enlarge the button on hover
            },
          }}
          onClick={() => setIsFullscreen(false)} // Exit fullscreen on click
        >
          <CloseIcon />
        </Box>
      )}
      <ReactPlayer
        ref={playerRef}
        url={m3u8Url || undefined}
        playing={playing} // Prevents playing while snackbar is open
        controls={false}
        width="100%"
        height="585px"
        onReady={() => setReady(true)}
        onProgress={handleProgress}
        playbackRate={playbackRate}
        muted={muted}
        volume={volume}
        onDuration={(duration) => setDuration(duration)}
        onPause={() => setPlaying(false)}


      />
      <Box
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '80%',
          backgroundColor: 'transparent',
          zIndex: 2,
          pointerEvents: 'auto',// Blocks interaction with underlying player
          cursor: 'pointer'
        }}
        onClick={(e) => {
          // e.stopPropagation();
          // if (playerRef.current && ready) {
          //   setPlaying(!playing); // Toggle play/pause
          //   if (playing) {
          //     playerRef.current?.getInternalPlayer().pauseVideo();
          //   } else {
          //     playerRef.current?.getInternalPlayer().playVideo();
          //   }
          // }
          e.stopPropagation();
          handlePlayPause();
        }}
      />
      {controlsVisible && (
        <PlayerControls
          startTimeCurrent={startInSeconds.toString()}
          endTimeCurrent={endInSeconds.toString()}
          playing={playing}
          playedSeconds={playedSeconds}
          duration={duration}
          volume={volume}
          muted={muted}
          playbackRate={playbackRate}
          anchorEl={anchorEl}
          menuAnchorEl={menuAnchorEl}
          hoveredTime={hoveredTime}
          handlePlayPause={handlePlayPause}
          handleVolumeClick={handleMute}
          handleVolumeChange={handleVolumeChange}
          handleSeekChange={handleSeekChange}
          handleSliderHover={handleSliderHover}
          handleSliderLeave={handleSliderLeave}
          handleMenuClick={handleMenuClick}
          handleMenuClose={handleMenuClose}
          handlePlaybackRateChange={handlePlaybackRateChange}
          handleMovieMenuClick={handleMovieMenuClick}
          handleMovieMenuClose={handleMovieMenuClose}
          handleNextVideo={handleNextVideo}
          handlePreviousVideo={handlePreviousVideo}
          handleTakeTest={handleTakeTest}
          handleMovieSelect={handleMovieSelect}
          movieList={movieList}
          handleFullscreenToggle={handleFullscreenToggle}
          isFullscreen={isFullscreen}
          handleToggleFloating={handleToggleFloating}
          isFloating={isFloating}
          handleSeek={handleSeek}
          setRewindSeconds={setRewindSeconds}
          setSkipSeconds={setSkipSeconds}
          rewindSeconds={rewindSeconds}
          skipSeconds={skipSeconds}
        />
      )}
      {/* <CountdownSnackbar
        open={snackbarOpen}
        message={snackbarMessage}
        onClose={() => setSnackbarOpen(false)}
        onTakeTest={handleTakeTest}
        onReplayChapter={handleReplayChapter}
        onCountdownFinish={handleCountdownFinish}
        onStartMovie={handleStartMovie}
        showReplayIcon={showReplayIcon}
        showTakeTestIcon={showTakeTestIcon}
        showStartMovieIcon={showStartMovieIcon}
        showContinueIcon={showContinueIcon}
        countdownOutside={countdownOutside}
      /> */}

    </Box>
  );
  return <Box>{isFloating ? renderFloatingPlayer() : renderFullPlayer()}</Box>;
};

export default ReactStreamingComponent;
