import React, { useState, useEffect } from "react";
import { Tabs, Input, Button, message } from "antd";
import DashboardComponent from "./DashboardComponent";

const { TabPane } = Tabs;

interface TabConfig {
    key: string;
    title: string;
    component: React.ReactNode;
    config: any; // To store configuration for each tab
}

const MainDashboard: React.FC = () => {
    const [tabs, setTabs] = useState<TabConfig[]>([{
        key: "1",
        title: "Dashboard 1",
        component: <DashboardComponent />,
        config: {},
    }]);
    const [activeTabKey, setActiveTabKey] = useState<string>("1");
    const [loading, setLoading] = useState(false);

    // Add a new tab
    const addTab = () => {
        const newKey = (tabs.length + 1).toString();
        setTabs((prevTabs) => [
            ...prevTabs,
            {
                key: newKey,
                title: `Dashboard ${newKey}`,
                component: <DashboardComponent />,
                config: {},
            },
        ]);
        setActiveTabKey(newKey);
    };

    // Remove a tab
    const removeTab = (targetKey: string) => {
        const newTabs = tabs.filter((tab) => tab.key !== targetKey);
        setTabs(newTabs);
        setActiveTabKey(newTabs.length ? newTabs[0].key : "");
    };

    // Edit a tab's title
    const editTabTitle = (key: string, newTitle: string) => {
        setTabs((prevTabs) =>
            prevTabs.map((tab) => (tab.key === key ? { ...tab, title: newTitle } : tab))
        );
    };

    const exportState = () => ({
        tabs: tabs.map((tab) => ({
            key: tab.key,
            title: tab.title,
            config: tab.config,
        })),
        activeTabKey,
    });

    const importState = (state: any) => {
        setTabs(state.tabs.map((tab: any) => ({
            ...tab,
            component: <DashboardComponent />,
        })));
        setActiveTabKey(state.activeTabKey);
    };

    const saveToServer = async () => {
        const state = exportState();
        try {
            await fetch('/api/save-dashboard', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(state),
            });
            message.success("Dashboard saved successfully!");
        } catch (error) {
            console.error("Error saving dashboard:", error);
            message.error("Failed to save dashboard.");
        }
    };

    const loadFromServer = async () => {
        try {
            const response = await fetch('/api/load-dashboard');
            const state = await response.json();
            importState(state);
            message.success("Dashboard loaded successfully!");
        } catch (error) {
            console.error("Error loading dashboard:", error);
            message.error("Failed to load dashboard.");
        }
    };

    return (
        <div>
            <div style={{ marginBottom: 16 }}>
                <Button type="primary" onClick={saveToServer} loading={loading} style={{ marginRight: 8 }}>
                    Save Tabs
                </Button>
                <Button onClick={loadFromServer} loading={loading}>
                    Load Tabs
                </Button>
            </div>

            <Tabs
                type="editable-card"
                activeKey={activeTabKey}
                onChange={(key) => setActiveTabKey(key)}
                onEdit={(key, action) => {
                    if (action === "add") addTab();
                    if (action === "remove") removeTab(key as string);
                }}
            >
                {tabs.map((tab) => (
                    <TabPane
                        tab={
                            <Input
                                value={tab.title}
                                onChange={(e) => editTabTitle(tab.key, e.target.value)}
                                style={{ width: 150 }}
                            />
                        }
                        key={tab.key}
                        closable={tabs.length > 1}
                    >
                        {tab.component}
                    </TabPane>
                ))}
            </Tabs>
        </div>
    );
};

export default MainDashboard;
