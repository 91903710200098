import React, { useEffect, useState } from "react";
import { Input, Tooltip } from "antd";
import { Line } from "@ant-design/charts";
import { ResizableBox } from "react-resizable";
import { EditOutlined } from "@ant-design/icons";
import "react-resizable/css/styles.css";

interface RealTimeLineChartProps {
    initialData: { x: number; y: number }[];
    socketUrl: string;
}

const RealTimeLineChart: React.FC<RealTimeLineChartProps> = ({ initialData, socketUrl }) => {
    const [chartSize, setChartSize] = useState({ width: 600, height: 400 }); // Default size
    const [title, setTitle] = useState("Real-Time Line Chart"); // Editable title
    const [editingTitle, setEditingTitle] = useState(false); // Whether the title is being edited
    const [hovered, setHovered] = useState(false); // Whether the chart is hovered
    const [data, setData] = useState(initialData);

    const config = {
        data,
        xField: "x",
        yField: "y",
        smooth: true,
        autoFit: false, // Disable auto-fit to parent container
        width: chartSize.width - 20, // Account for padding
        height: chartSize.height - 60, // Account for padding
        appendPadding: 10,
    };

    // WebSocket connection
    useEffect(() => {
        const socket = new WebSocket(socketUrl);

        socket.onmessage = (event) => {
            const newPoint = JSON.parse(event.data);
            setData((prevData) => [...prevData.slice(-99), newPoint]); // Keep last 100 points
        };

        return () => socket.close();
    }, [socketUrl]);

    const handleResize = (event: any, { size }: { size: { width: number; height: number } }) => {
        setChartSize(size); // Dynamically update chart size on resize
    };

    return (
        <div
            onMouseEnter={() => setHovered(true)}
            onMouseLeave={() => setHovered(false)}
            style={{
                position: "relative",
                marginBottom: 20,
                padding: "10px",
                borderRadius: "8px",
                backgroundColor: "#fff",
                transition: "box-shadow 0.3s",
                boxShadow: hovered ? "0 4px 8px rgba(0, 0, 0, 0.2)" : "none",
            }}
        >
            <ResizableBox
                width={chartSize.width}
                height={chartSize.height}
                minConstraints={[300, 200]} // Minimum width and height
                maxConstraints={[1200, 800]} // Maximum width and height
                resizeHandles={hovered ? ["se", "e", "s"] : []} // Show handles only when hovered
                onResizeStop={handleResize} // Update size when resizing stops
                style={{
                    width: "100%",
                    height: "100%",
                }}
            >
                <div>
                    <div style={{ display: "flex", alignItems: "center", marginBottom: 10 }}>
                        {editingTitle ? (
                            <Input
                                value={title}
                                onChange={(e) => setTitle(e.target.value)} // Update title
                                onBlur={() => setEditingTitle(false)} // Stop editing when input loses focus
                                autoFocus
                                style={{
                                    fontSize: "16px",
                                    fontWeight: "bold",
                                    border: "none",
                                    padding: 0,
                                }}
                                placeholder="Enter chart title"
                            />
                        ) : (
                            <Tooltip title="Click to edit the chart title">
                                <div
                                    style={{
                                        fontSize: "16px",
                                        fontWeight: "bold",
                                        cursor: "pointer",
                                        display: "flex",
                                        alignItems: "center",
                                    }}
                                    onClick={() => setEditingTitle(true)} // Enable editing on click
                                >
                                    {title}
                                    <EditOutlined style={{ marginLeft: 8 }} />
                                </div>
                            </Tooltip>
                        )}
                    </div>
                    <Line {...config} />
                </div>
            </ResizableBox>
        </div>
    );
};

export default RealTimeLineChart;
