import React, { useState, useEffect } from "react";
import { Select, Row, Col, Button } from "antd";

const { Option } = Select;

interface MeasureDimensionSelectorProps {
    measures: any[];
    dimensions: any[];
    selectedMeasures: string[];
    selectedDimensions: string[];
    selectedFilters: { dimension: string; value: string }[];
    setSelectedMeasures: (measures: string[]) => void;
    setSelectedDimensions: (dimensions: string[]) => void;
    setSelectedFilters: (filters: { dimension: string; value: string }[]) => void;
    fetchDimensionValues: (dimension: string) => Promise<string[]>;
}

const MeasureDimensionSelector: React.FC<MeasureDimensionSelectorProps> = ({
    measures,
    dimensions,
    selectedMeasures,
    selectedDimensions,
    selectedFilters,
    setSelectedMeasures,
    setSelectedDimensions,
    setSelectedFilters,
    fetchDimensionValues,
}) => {
    const [availableFilters, setAvailableFilters] = useState<string[]>([]);
    const [dimensionValues, setDimensionValues] = useState<Record<string, string[]>>({});

    const handleMeasureChange = (values: string[]) => {
        setSelectedMeasures(values);
    };

    const handleDimensionChange = (values: string[]) => {
        setSelectedDimensions(values);
    };

    const handleFilterAdd = (dimension: string) => {
        if (!availableFilters.includes(dimension)) {
            setAvailableFilters([...availableFilters, dimension]);
            fetchDimensionValues(dimension).then((values) => {
                setDimensionValues((prev) => ({
                    ...prev,
                    [dimension]: ["All", ...values],
                }));
            });
        }
    };

    const handleFilterValueChange = (dimension: string, value: string) => {
        setSelectedFilters([
            ...selectedFilters.filter((filter) => filter.dimension !== dimension),
            ...(value !== "All" ? [{ dimension, value }] : []),
        ]);
    };

    const handleFilterDelete = (dimension: string) => {
        setAvailableFilters((prev) => prev.filter((filter) => filter !== dimension));
        setSelectedFilters(
            selectedFilters.filter((filter) => filter.dimension !== dimension)
        );
        setDimensionValues((prev) => {
            const updated = { ...prev };
            delete updated[dimension];
            return updated;
        });
    };

    return (
        <Row gutter={[16, 16]}>
            <Col span={8}>
                <h4>Measures</h4>
                <Select
                    mode="multiple"
                    style={{ width: "100%" }}
                    placeholder="Select measures"
                    value={selectedMeasures}
                    onChange={handleMeasureChange}
                    allowClear
                >
                    {measures.map((measure) => (
                        <Option key={measure.name} value={measure.name}>
                            {measure.title || measure.name}
                        </Option>
                    ))}
                </Select>
            </Col>
            <Col span={8}>
                <h4>Dimensions</h4>
                <Select
                    mode="multiple"
                    style={{ width: "100%" }}
                    placeholder="Select dimensions"
                    value={selectedDimensions}
                    onChange={handleDimensionChange}
                    allowClear
                >
                    {dimensions.map((dimension) => (
                        <Option key={dimension.name} value={dimension.name}>
                            {dimension.title || dimension.name}
                        </Option>
                    ))}
                </Select>
            </Col>
            <Col span={8}>
                <h4>Filters</h4>
                <Select
                    style={{ width: "100%", marginBottom: 10 }}
                    placeholder="Add a filter"
                    onChange={handleFilterAdd}
                    value={undefined}
                >
                    {dimensions
                        .filter((dim) => !availableFilters.includes(dim.name))
                        .map((dimension) => (
                            <Option key={dimension.name} value={dimension.name}>
                                {dimension.title || dimension.name}
                            </Option>
                        ))}
                </Select>
                {availableFilters.map((dimension) => (
                    <div key={dimension} style={{ marginBottom: 10 }}>
                        <label>
                            {dimensions.find((dim) => dim.name === dimension)?.title || dimension}
                        </label>
                        <Row gutter={[8, 8]}>
                            <Col flex="auto">
                                <Select
                                    style={{ width: "100%" }}
                                    placeholder={`Select value for ${dimension}`}
                                    onChange={(value) => handleFilterValueChange(dimension, value)}
                                    value={
                                        selectedFilters.find((filter) => filter.dimension === dimension)?.value ||
                                        undefined
                                    }
                                >
                                    {(dimensionValues[dimension] || []).map((value) => (
                                        <Option key={value} value={value}>
                                            {value}
                                        </Option>
                                    ))}
                                </Select>
                            </Col>
                            <Col>
                                <Button
                                    danger
                                    size="small"
                                    onClick={() => handleFilterDelete(dimension)}
                                >
                                    Delete
                                </Button>
                            </Col>
                        </Row>
                    </div>
                ))}
            </Col>
        </Row>
    );
};

export default MeasureDimensionSelector;
