import React, { useEffect, useState } from 'react';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { materialLight } from 'react-syntax-highlighter/dist/esm/styles/prism'; // White/light theme

interface CodeViewerProps {
    blobUrl: string; // Blob location
    language?: string; // Programming language, default is Python
}

const CodeViewer: React.FC<CodeViewerProps> = ({ blobUrl, language = 'python' }) => {
    const [code, setCode] = useState<string | null>(null);
    const [error, setError] = useState<string | null>(null);

    useEffect(() => {
        // Fetch the blob and convert it to a string
        const fetchBlobContent = async () => {
            try {
                const response = await fetch(blobUrl);
                if (!response.ok) {
                    throw new Error(`Failed to fetch blob: ${response.statusText}`);
                }
                const blob = await response.blob();
                const text = await blob.text();
                setCode(text); // Set the code to be displayed
            } catch (err) {
                if (err instanceof Error) {
                    setError(err.message);
                } else {
                    setError('An unknown error occurred.');
                }
            }
        };

        fetchBlobContent();
    }, [blobUrl]);

    if (error) {
        return <div>Error: {error}</div>;
    }

    if (code === null) {
        return <div>Loading...</div>; // Show a loading state while fetching
    }

    return (
        <SyntaxHighlighter
            language={language}
            style={materialLight} // Light theme for a white background
            showLineNumbers={true} // Enable line numbers
            lineNumberStyle={{ color: '#888', marginRight: '10px' }} // Line number style
        >
            {code}
        </SyntaxHighlighter>
    );
};

export default CodeViewer;
