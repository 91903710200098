
import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { getAllArticlesIdsAndTopicNumberByCourseId, getFlowsMain } from '../api/serverApis/flowApi';

export interface flowsState {
    flowsData: any;
    flowsArticleAndTopic: any;
    loading: boolean;
    error: string | null | undefined;
}

const initialState: flowsState = {
    flowsData: null,
    flowsArticleAndTopic: null,
    loading: false,
    error: null,
};

export const getAllArticlesIdsAndTopicNumberByCourseIdSlice = createAsyncThunk('flow/getAllArticlesIdsAndTopicNumberByCourseIdSlice', async ({ courseId }: { courseId: string }) => {
    const response = await getAllArticlesIdsAndTopicNumberByCourseId(courseId);
    return response;
});

export const getFlowsMainSlice = createAsyncThunk('flow/getFlowsMain', async ({ articleId }: { articleId: string }) => {
    const response = await getFlowsMain(articleId);
    return response;
});


const handleState = (action: any) => {
    return (builder: any) => {
        builder
            .addCase(action.pending, (state: any) => {
                state.loading = true;
            })
            .addCase(action.fulfilled, (state: any, action: PayloadAction<any>) => {
                state.loading = false;
                if (action.type === 'flow/getAllArticlesIdsAndTopicNumberByCourseIdSlice/fulfilled') {
                    state.flowsArticleAndTopic = action.payload;
                }
                else if (action.type === 'flow/getFlowsMain/fulfilled') {
                    state.flowsData = action.payload;
                }
            })
            .addCase(action.rejected, (state: any, action: any) => {
                state.loading = false;
                state.error = action.error.message;
            });
    };
}

const quizSlice = createSlice({
    name: 'quiz',
    initialState: initialState,
    reducers: {

    },
    extraReducers: (builder) => {
        handleState(getAllArticlesIdsAndTopicNumberByCourseIdSlice)(builder);
        handleState(getFlowsMainSlice)(builder);
    },
});

export default quizSlice.reducer;

