import axiosInstance from "../interceptor";
import { GATEWAY_API_URL } from "../api";

const api = '/api/flowService/api/v1/flows';

export const getFlowsMain = async (articleId: string) => {
    try {
        const response = await axiosInstance.post(`${GATEWAY_API_URL}${api}/getFlowsByArticleId`, { articleId });
        return response.data;
    } catch (error) {
        console.error(`Flow API: [getFlowsMain]: ${error}`);
        throw error;
    }
};
export const getAllArticlesIdsAndTopicNumberByCourseId = async (courseId: string) => {
    try {
        const response = await axiosInstance.post(`${GATEWAY_API_URL}${api}/getAllArticlesIdsAndTopicNumberByCourseId`, { courseId });
        return response.data;
    } catch (error) {
        console.error(`Flow API: [getAllArticlesIdsAndTopicNumberByCourseId]: ${error}`);
        throw error;
    }
}
