import React, { useState } from "react";
import { Line } from "@ant-design/charts";
import { ResizableBox } from "react-resizable";
import { Input, Tooltip } from "antd";
import { EditOutlined } from "@ant-design/icons";
import "react-resizable/css/styles.css";

interface SparklineProps {
    data: any[]; // Data for the sparkline
    xField: string; // Field for the x-axis
    yField: string; // Field for the y-axis
}

const ResizableSparkline: React.FC<SparklineProps> = ({ data, xField, yField }) => {
    const [chartSize, setChartSize] = useState({ width: 300, height: 100 }); // Initial size
    const [title, setTitle] = useState("Sparkline Chart"); // Editable title
    const [editingTitle, setEditingTitle] = useState(false); // Whether the title is being edited
    const [hovered, setHovered] = useState(false); // Whether the graph is hovered

    const config = {
        data,
        xField,
        yField,
        autoFit: false, // Disable auto-fit to parent container
        width: chartSize.width - 20, // Adjust for padding
        height: chartSize.height - 20, // Adjust for padding
        smooth: true, // Smooth lines for better visuals
        color: "#1890ff",
        point: { size: 2, shape: "circle" },
        lineStyle: { lineWidth: 2 },
        tooltip: false, // Disable tooltips for a minimalist look
    };

    const handleResize = (event: any, { size }: { size: { width: number; height: number } }) => {
        setChartSize(size); // Dynamically update chart size on resize
    };

    return (
        <div
            onMouseEnter={() => setHovered(true)}
            onMouseLeave={() => setHovered(false)}
            style={{
                position: "relative",
                marginBottom: 20,
                padding: "10px",
                borderRadius: "8px",
                backgroundColor: "#fff",
                transition: "box-shadow 0.3s",
                boxShadow: hovered ? "0 4px 8px rgba(0, 0, 0, 0.2)" : "none",
            }}
        >
            <ResizableBox
                width={chartSize.width}
                height={chartSize.height}
                minConstraints={[150, 50]} // Minimum width and height
                maxConstraints={[600, 200]} // Maximum width and height
                resizeHandles={hovered ? ["se", "e", "s"] : []} // Show handles only when hovered
                onResizeStop={handleResize} // Update size when resizing stops
                style={{
                    width: "100%",
                    height: "100%",
                }}
            >
                <div>
                    <div style={{ display: "flex", alignItems: "center", marginBottom: 10 }}>
                        {editingTitle ? (
                            <Input
                                value={title}
                                onChange={(e) => setTitle(e.target.value)}
                                onBlur={() => setEditingTitle(false)}
                                autoFocus
                                style={{
                                    fontSize: "16px",
                                    fontWeight: "bold",
                                    border: "none",
                                    padding: 0,
                                }}
                                placeholder="Enter chart title"
                            />
                        ) : (
                            <Tooltip title="Click to edit the chart title">
                                <div
                                    style={{
                                        fontSize: "16px",
                                        fontWeight: "bold",
                                        cursor: "pointer",
                                        display: "flex",
                                        alignItems: "center",
                                    }}
                                    onClick={() => setEditingTitle(true)}
                                >
                                    {title}
                                    <EditOutlined style={{ marginLeft: 8 }} />
                                </div>
                            </Tooltip>
                        )}
                    </div>
                    <Line {...config} />
                </div>
            </ResizableBox>
        </div>
    );
};

export default ResizableSparkline;
