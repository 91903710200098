import React, { useState, useEffect, useRef } from 'react';
import { pdfjs, Document, Page } from 'react-pdf';
import { CircularProgress, Box, IconButton, Typography, Stack, Tooltip, useTheme, InputBase, Button, LinearProgress } from '@mui/material';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import ZoomOutIcon from '@mui/icons-material/ZoomOut';
import PrintIcon from '@mui/icons-material/Print';
import FitScreenIcon from '@mui/icons-material/FitScreen';
import RotateLeftIcon from '@mui/icons-material/RotateLeft';
import RotateRightIcon from '@mui/icons-material/RotateRight';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import LastPageIcon from '@mui/icons-material/LastPage';
import { setCurrentlyPlayingSubVideo } from '../../features/PlayerTocSlice';
import { useDispatch } from 'react-redux';
import OpenInBrowserIcon from '@mui/icons-material/OpenInBrowser';
import { AppDispatch } from '../../store';
import LoadingDots from '../combinationSearchComponents/mainComponenets/LoadingDots';

// Configure PDF.js worker
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

interface PDFViewerProps {
    fileUrl: string;
    startPage: number;
    endPage: number;
    isPopup?: boolean;
    pdfText?: string;
    videoData?: any;
}

const PDFViewer: React.FC<PDFViewerProps> = ({ fileUrl, startPage: initialStartPage, endPage: initialEndPage, isPopup, pdfText, videoData }) => {
    const theme = useTheme();
    const [numPages, setNumPages] = useState<number | null>(null);
    const [scale, setScale] = useState<number>(1); // Default zoom is 100%
    const [rotate, setRotate] = useState<number>(0);
    const [currentPage, setCurrentPage] = useState<number>(initialStartPage ?? 1);
    const [startPage, setStartPage] = useState<number>(initialStartPage);
    const [endPage, setEndPage] = useState<number>(initialEndPage);
    const [editingPages, setEditingPages] = useState<boolean>(false);
    const [manualZoom, setManualZoom] = useState<string>('100');
    const [useEmbeddedViewer, setUseEmbeddedViewer] = useState<boolean>(true);
    const dispatch = useDispatch<AppDispatch>();
    const inputContainerRef = useRef<HTMLDivElement | null>(null);
    const canvasRef = useRef<HTMLCanvasElement | null>(null);
    const containerRef = useRef<HTMLDivElement | null>(null);
    const [loadingProgress, setLoadingProgress] = useState<boolean>(true);
    const [pageLoadingStates, setPageLoadingStates] = useState<{ [key: number]: boolean }>({});
    useEffect(() => {
        setCurrentPage(initialStartPage ?? 1);
        setStartPage(initialStartPage);
        setEndPage(initialEndPage);
        if (videoData) {
            dispatch(setCurrentlyPlayingSubVideo({ movieId: videoData.movieId, subVideoId: videoData.id, pageNumber: startPage }));
        }
    }, [initialStartPage, initialEndPage]);

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (inputContainerRef.current && !inputContainerRef.current.contains(event.target as Node)) {
                setEditingPages(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
        if (videoData) {
            dispatch(setCurrentlyPlayingSubVideo({ movieId: videoData.movieId, subVideoId: videoData.id, pageNumber: startPage }));
        }
    }, [inputContainerRef]);


    const onPageChange = (pageNumber: number) => {
        setCurrentPage(pageNumber);
        setEditingPages(false);
    };
    const adjustZoomToFitWidth = (page: any) => {
        const viewport = page.getViewport({ scale: 1 }); // Get the original size of the page
        const size = page.getViewport().viewBox[3]
        // Calculate the scale based on the container width and PDF page width
        if (size > 900) {
            setScale(0.45);
            setManualZoom("45");
        }
    };

    const adjustZoomToFitHeight = (page: any) => {
        const viewport = page.getViewport({ scale: 1 }); // Get the original size of the page
        const containerHeight = containerRef.current?.offsetHeight ?? 0; // Get container height

        // Calculate the scale based on the container height and PDF page height
        const newScale = containerHeight / viewport.height;
        setScale(newScale);
        setManualZoom(Math.round(newScale * 100).toString()); // Update the displayed zoom percentage
    };
    const onDocumentLoadSuccess = ({ numPages }: { numPages: number }) => {
        if (videoData) {
            dispatch(setCurrentlyPlayingSubVideo({ movieId: videoData.movieId, subVideoId: videoData.id, pageNumber: startPage }));
        }
        setNumPages(numPages);
        setLoadingProgress(false);

        // Automatically adjust zoom to fit width or height based on your preference
        const page = pdfjs.getDocument(fileUrl).promise.then((pdfDoc) => pdfDoc.getPage(1)); // Get the first page

        page.then((loadedPage) => {
            adjustZoomToFitWidth(loadedPage); // You can also call adjustZoomToFitHeight here
        });
    };

    const handleStartPageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setStartPage(parseInt(e.target.value, 10));
    };

    const handleEndPageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setEndPage(parseInt(e.target.value, 10));
    };

    const handlePageInputSubmit = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter') {
            if (
                !isNaN(startPage) &&
                !isNaN(endPage) &&
                startPage >= 1 &&
                endPage <= (numPages || endPage) &&
                startPage <= endPage
            ) {
                onPageChange(startPage);
            } else {
                setEditingPages(false);
            }
        }
    };

    const zoomIn = () => {
        setScale((prevScale) => Math.min(prevScale + 0.25, 3.0));
        setManualZoom(Math.round((scale + 0.25) * 100).toString());
    };

    const zoomOut = () => {
        setScale((prevScale) => Math.max(prevScale - 0.25, 0.1));
        setManualZoom(Math.round((scale - 0.25) * 100).toString());
    };
    const handleManualZoomChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const zoomValue = parseFloat(e.target.value);
        if (!isNaN(zoomValue) && zoomValue >= 10 && zoomValue <= 300) { // Ensuring the zoom is between 10% and 300%
            setScale(zoomValue / 100);
        }
        setManualZoom(e.target.value);
    };
    const printDocument = () => {
        window.print();
    };

    const fitToWidth = () => {
        setScale(0.4);
    };

    const fitToHeight = () => {
        setScale(2.0); // Adjust this value as necessary to fit your height
    };

    const rotateLeft = () => {
        setRotate((prevRotate) => prevRotate - 90);
    };

    const rotateRight = () => {
        setRotate((prevRotate) => prevRotate + 90);
    };

    const firstPage = () => {
        onPageChange(1);
    };

    const lastPage = () => {
        if (numPages) {
            onPageChange(numPages);
        }
    };

    const toggleViewer = () => {
        setUseEmbeddedViewer((prev) => !prev);
    };


    const highlightTextOnCanvas = (page: any, canvasContext: any, viewport: any, textItems: any) => {
        if (!pdfText) return;

        // Split pdfText into groups of three words
        const searchPhrases = pdfText
            .toLowerCase()
            .split(/\s+/)
            .reduce((result: any, word: any, index: any, array: any) => {
                if (index % 3 === 0 && array[index + 2]) {
                    result.push(`${word} ${array[index + 1]} ${array[index + 2]}`);
                }
                return result;
            }, []);

        const highlightedSentences = new Set(); // Track highlighted sentences to avoid multiple highlights

        // Loop through text items (which represent the text content of the page)
        textItems.items.forEach((item: any, itemIndex: number) => {
            let itemTextLower = item.str.toLowerCase();

            // Check if any of the search phrases match the current text item
            const containsPhrase = searchPhrases.some((phrase: any) => itemTextLower.includes(phrase));

            // If a matching phrase is found and the sentence hasn't been highlighted yet
            if (containsPhrase && !highlightedSentences.has(itemIndex)) {
                const transform = pdfjs.Util.transform(
                    pdfjs.Util.transform(viewport.transform, item.transform),
                    [1, 0, 0, -1, 0, 0]
                );

                // Draw an orange rectangle behind the text
                canvasContext.fillStyle = 'rgba(255, 165, 0, 0.4)'; // Orange background color with some transparency
                canvasContext.fillRect(
                    transform[4], // X position of the rectangle
                    transform[5] - item.height, // Y position (adjusted for text height)
                    item.width, // Width of the rectangle (covering the entire text width)
                    item.height // Height of the rectangle
                );

                // Mark this sentence as highlighted
                highlightedSentences.add(itemIndex);
            }
        });
    };

    // const adjustZoomToFitWidth = (page: any) => {
    //     const viewport = page.getViewport({ scale: 1 }); // Get the original size of the page
    //     const containerWidth = containerRef.current?.offsetWidth ?? 0; // Get container width

    //     // Set the scale based on the container width and PDF page width
    //     const newScale = containerWidth / viewport.width;
    //     setScale(newScale);
    //     setManualZoom(Math.round(newScale * 100).toString()); // Update zoom percentage
    // };

    const renderPage = async (page: any, canvasRef: any) => {
        const viewport = page.getViewport({ scale });
        const canvas = canvasRef.current;
        const canvasContext = canvas.getContext('2d');
        //adjustZoomToFitWidth(page);
        // Set the canvas dimensions to match the page
        canvas.height = viewport.height;
        canvas.width = viewport.width;

        // Render the page into the canvas
        const renderContext = {
            canvasContext,
            viewport,
        };
        await page.render(renderContext).promise;
        // Get the text content
        const textContent = await page.getTextContent();

        // Highlight the text on the canvas
        highlightTextOnCanvas(page, canvasContext, viewport, textContent);
    };
    const canvasRefs = useRef<{ [key: number]: HTMLCanvasElement | null }>({});
    const renderPages = () => {
        if (!numPages) return null;
        const validStartPage = Math.max(1, startPage);
        const validEndPage = Math.min(numPages, endPage);
        if (validEndPage < validStartPage) return <Typography>Invalid page range</Typography>;
        // Store refs for each page
        return Array.from(
            { length: validEndPage - validStartPage + 1 },
            (_, index) => validStartPage + index
        ).map((pageIndex) => {
            const isPageLoading = !pageLoadingStates[pageIndex];
            return (
                <Box
                    key={`page_${pageIndex}`}
                    sx={{
                        position: 'relative',
                        marginTop: '30px',
                        marginBottom: '30px',
                        border: '1px solid #ccc',
                        borderRadius: '4px',
                        padding: '8px',
                        backgroundColor: theme.palette.background.paper,
                    }}
                >
                    {/* Spinner overlay */}
                    {isPageLoading && (
                        <Box
                            sx={{
                                position: 'absolute',
                                top: -200,
                                left: 0,
                                width: '100%',
                                height: '100%',
                                backgroundColor: 'rgba(255, 255, 255, 0.7)',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                zIndex: 10,
                            }}
                        >
                            <LoadingDots message={"Loading PDF page"} />

                        </Box>
                    )}

                    {/* Page Number */}
                    <Typography
                        sx={{
                            position: 'absolute',
                            top: -24,
                            left: '50%',
                            transform: 'translateX(-50%)',
                            backgroundColor: theme.palette.background.default,
                            zIndex: 0,
                            padding: '2px 4px',
                            borderRadius: '4px',
                            color: theme.palette.text.primary,
                        }}
                    >
                        {pageIndex}
                    </Typography>

                    {/* PDF Page */}

                    {pdfText ? (
                        <>
                            <canvas ref={(el) => {
                                canvasRefs.current[pageIndex] = el; // Assign the canvas ref for the current page
                            }} />
                            <Page
                                pageNumber={pageIndex}
                                scale={scale}
                                rotate={rotate}
                                renderAnnotationLayer={false}
                                renderTextLayer={false}
                                onRenderSuccess={
                                    async (page) => {
                                        setPageLoadingStates((prev) => ({
                                            ...prev,
                                            [pageIndex]: true,
                                        }));
                                        const canvasRef = canvasRefs.current[pageIndex];
                                        if (canvasRef) {
                                            await renderPage(page, { current: canvasRef });
                                        }
                                    }
                                }
                            />
                        </>
                    ) : (
                        <Page
                            pageNumber={pageIndex}
                            scale={scale}
                            rotate={rotate}
                            renderAnnotationLayer={false}
                            renderTextLayer={false} // Regular text layer rendering
                            onRenderSuccess={() => {
                                setPageLoadingStates((prev) => ({
                                    ...prev,
                                    [pageIndex]: true,
                                }));
                            }}
                        />
                    )}
                </Box>
            );
        });
    };

    return (
        <Box sx={{ p: 2, textAlign: 'center', position: 'relative' }}>
            {loadingProgress && (
                <LoadingDots message={"Waiting for PDF"} />
            )}
            {useEmbeddedViewer ? (
                <>
                    <Stack
                        direction="row"
                        alignItems="center"
                        sx={{
                            position: 'fixed',
                            bottom: 0,
                            right: 90,
                            left: isPopup ? 0 : 'none',
                            backgroundColor: theme.palette.background.paper,
                            zIndex: 1000,
                            p: 1,
                            width: isPopup ? '80%' : '100%',
                            paddingLeft: isPopup ? '50px' : '300px',
                            marginBottom: isPopup ? '45px' : '',
                            marginLeft: isPopup ? '80px' : '',
                        }}
                        spacing={0} // Add spacing between the elements
                    >
                        <Tooltip title="Page Range">
                            <Box
                                ref={inputContainerRef}
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    cursor: 'pointer',
                                    px: 1,
                                }}
                                onClick={() => setEditingPages(true)}
                            >
                                {editingPages ? (
                                    <Box sx={{ display: 'flex', gap: 1 }}>
                                        <InputBase
                                            value={startPage}
                                            onChange={handleStartPageChange}
                                            onKeyDown={handlePageInputSubmit}
                                            autoFocus
                                            sx={{ width: 40, textAlign: 'center' }}
                                            inputProps={{ style: { textAlign: 'center' } }}
                                        />
                                        <Typography variant="caption">-</Typography>
                                        <InputBase
                                            value={endPage}
                                            onChange={handleEndPageChange}
                                            onKeyDown={handlePageInputSubmit}
                                            sx={{ width: 40, textAlign: 'center' }}
                                            inputProps={{ style: { textAlign: 'center' } }}
                                        />
                                    </Box>
                                ) : (
                                    <Typography variant="caption">
                                        <Box sx={{ border: '1px solid', fontWeight: 'bold', background: theme.palette.background.paper, padding: '4px' }}>
                                            {startPage}-{endPage}
                                        </Box>
                                    </Typography>
                                )}
                            </Box>
                        </Tooltip>
                        <Tooltip title="Zoom Out">
                            <IconButton onClick={zoomOut} disabled={scale <= 0.1}>
                                <ZoomOutIcon />
                            </IconButton>
                        </Tooltip>
                        {/* <Typography variant="caption">{Math.round(scale * 100)}%</Typography> Display zoom percentage */}
                        <Tooltip title="Manual Zoom">
                            <InputBase
                                value={manualZoom}
                                onChange={handleManualZoomChange}
                                sx={{
                                    width: '50px',
                                    textAlign: 'center',
                                    backgroundColor: theme.palette.background.paper,
                                    borderRadius: 2,
                                    margin: '0 5px',
                                    border: '1px solid #ccc',
                                    padding: '0 10px'
                                }}
                                inputProps={{ style: { textAlign: 'center' }, maxLength: 3 }}
                            />
                        </Tooltip>
                        <Tooltip title="Zoom In">
                            <IconButton onClick={zoomIn} disabled={scale >= 3.0}>
                                <ZoomInIcon />
                            </IconButton>
                        </Tooltip>

                        <Tooltip title="Fit to Width">
                            <IconButton onClick={fitToWidth}>
                                <FitScreenIcon />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="Rotate Left">
                            <IconButton onClick={rotateLeft}>
                                <RotateLeftIcon />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="Rotate Right">
                            <IconButton onClick={rotateRight}>
                                <RotateRightIcon />
                            </IconButton>
                        </Tooltip>
                        <Button
                            variant="outlined"
                            startIcon={<OpenInBrowserIcon />}
                            onClick={toggleViewer}
                        >
                            Browser Viewer
                        </Button>
                    </Stack>

                    <Box
                        sx={{
                            display: 'block',
                            position: 'relative',
                            backgroundColor: theme.palette.background.paper,
                            overflow: 'hidden',
                            mt: 1,
                            marginBottom: isPopup ? '500px' : '100px',
                        }}
                    >
                        <Document file={fileUrl} onLoadSuccess={onDocumentLoadSuccess} loading="">
                            {renderPages()}
                        </Document>
                    </Box>
                </>
            ) : (
                <Box sx={{ mt: 0 }}>
                    <iframe
                        src={fileUrl}
                        width="100%"
                        height={isPopup ? "496px" : "560px"}
                        title="PDF Viewer"
                        style={{ border: 'none' }}
                    />
                    <Button
                        variant="outlined"
                        startIcon={<OpenInBrowserIcon />}
                        sx={{ mt: 0, height: '30px', position: 'fixed', bottom: isPopup ? 44 : 10, left: isPopup ? 230 : 380 }}
                        onClick={toggleViewer}
                    >
                        Back to Regular Viewer
                    </Button>
                </Box>
            )
            }
        </Box >
    );
};

export default PDFViewer;