import React, { useState, useEffect } from "react";
import cubejsApi from "./cubeApi/cubeApi";
import DashboardCard from "./DashboardCard";

const DashboardComponent: React.FC = () => {
    const [cards, setCards] = useState<any[]>([]);
    const [meta, setMeta] = useState<any[]>([]); // Separate state to store meta data

    useEffect(() => {
        const fetchMeta = async () => {
            try {
                const metaData = await cubejsApi.meta();
                setMeta(metaData.cubes); // Store meta data
                setCards((prevCards) =>
                    prevCards.map((card) => ({ ...card, meta: metaData.cubes }))
                );
            } catch (err) {
                console.error("Error fetching Cube.js metadata:", err);
            }
        };

        fetchMeta();
    }, []);

    const fetchDimensionValues = async (dimension: string): Promise<string[]> => {
        try {
            const query = {
                dimensions: [dimension],
                limit: 100, // Adjust limit as needed
            };
            const resultSet = await cubejsApi.load(query);
            return resultSet.tablePivot().map((row) => String(row[dimension])); // Ensure all values are strings
        } catch (err) {
            console.error(`Error fetching values for dimension "${dimension}":`, err);
            return [];
        }
    };

    const addCard = () => {
        const newCard = {
            id: `card-${Date.now()}`,
            meta: meta, // Use the already fetched meta data
            selectedCubes: [],
            measures: [],
            dimensions: [],
            selectedMeasures: [],
            selectedDimensions: [],
            selectedFilters: [],
            data: [],
            chartVisibility: [],
            error: null,
            loading: false,
        };
        setCards((prevCards) => [...prevCards, newCard]);
    };

    const updateCard = (id: string, updates: any) => {
        setCards((prevCards) =>
            prevCards.map((card) => (card.id === id ? { ...card, ...updates } : card))
        );
    };

    const removeCard = (id: string) => {
        setCards((prevCards) => prevCards.filter((card) => card.id !== id));
    };

    const fetchData = async (id: string) => {
        const card = cards.find((card) => card.id === id);
        if (!card) return;

        const { selectedCubes, selectedMeasures, selectedDimensions, selectedFilters } = card;

        if (!selectedCubes.length) return;

        updateCard(id, { loading: true, error: null });

        try {
            const query: any = {
                measures: selectedMeasures,
                dimensions: selectedDimensions,
                filters: selectedFilters.map((filter: any) => ({
                    dimension: filter.dimension,
                    operator: "equals",
                    values: [filter.value],
                })), // Add filters to the query
            };
            const resultSet = await cubejsApi.load(query);

            updateCard(id, { data: resultSet.tablePivot(), loading: false });
        } catch (err) {
            console.error("Error fetching data:", err);
            updateCard(id, { error: "Failed to fetch data.", loading: false });
        }
    };

    const exportState = () => ({
        meta,
        cards: cards.map((card) => card.exportState()),
    });

    const importState = (state: any) => {
        setMeta(state.meta);
        setCards(
            state.cards.map((cardState: any) => ({
                ...cardState,
                importState: (newState: any) => {
                    cardState.importState(newState);
                },
            }))
        );
    };

    return (
        <div style={{ padding: 20, background: "#f0f2f5", minHeight: "100vh" }}>
            <button
                onClick={addCard}
                style={{
                    marginBottom: 16,
                    padding: "8px 16px",
                    backgroundColor: "#1890ff",
                    color: "#fff",
                    border: "none",
                    borderRadius: "4px",
                    cursor: "pointer",
                }}
            >
                Add Dashboard
            </button>
            <div
                style={{
                    display: "flex",
                    flexWrap: "wrap",
                    gap: "16px",
                }}
            >
                {cards.map((card) => (
                    <div key={card.id} style={{ flex: "1 1 auto", minWidth: "300px", maxWidth: "100%" }}>
                        <DashboardCard
                            {...card}
                            onUpdate={(updates) => updateCard(card.id, updates)}
                            onRemove={() => removeCard(card.id)}
                            onFetchData={() => fetchData(card.id)}
                            fetchDimensionValues={fetchDimensionValues} // Pass function as prop
                        />
                    </div>
                ))}
            </div>
        </div>
    );
};

export default DashboardComponent;
