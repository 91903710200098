import React, { useState, useEffect } from "react";
import { Input, Tooltip, Select, Row, Col, Button, InputNumber } from "antd";
import { Pie } from "@ant-design/charts";
import { ResizableBox } from "react-resizable";
import { EditOutlined } from "@ant-design/icons";
import "react-resizable/css/styles.css";
import { EyeInvisibleOutlined, EyeOutlined } from "@ant-design/icons";
import { groupBy, sumBy } from "lodash";

const { Option } = Select;

interface PieChartProps {
    data: any[];
    availableKeys?: string[];
    defaultAngleField: string;
    defaultColorField: string;
    angleField?: string; // Add this if it should be optional
    colorField?: string; // Add this if it should be optional
}

const ResizablePieChart: React.FC<PieChartProps> = ({
    data,
    availableKeys,
    defaultAngleField,
    defaultColorField,
}) => {
    const [chartSize, setChartSize] = useState({ width: 400, height: 400 });
    const [title, setTitle] = useState("Resizable Pie Chart");
    const [editingTitle, setEditingTitle] = useState(false);
    const [hovered, setHovered] = useState(false);

    const [angleField, setAngleField] = useState<string>(defaultAngleField);
    const [colorField, setColorField] = useState<string>(defaultColorField);
    const [filters, setFilters] = useState<{ field: string; value: string }[]>([]);
    const [dimensionValues, setDimensionValues] = useState<Record<string, string[]>>({});
    const [keyword, setKeyword] = useState<string>("");
    const [topN, setTopN] = useState<number | null>(null);

    const [dropdownVisibility, setDropdownVisibility] = useState({
        angleField: false,
        colorField: false,
        filters: false,
        keyword: false,
        topN: false,
    });

    const keys = availableKeys || (data.length > 0 ? Object.keys(data[0]) : []);

    useEffect(() => {
        if (!keys.includes(angleField)) setAngleField(keys[0]);
        if (!keys.includes(colorField)) setColorField(keys[1]);
    }, [keys, angleField, colorField]);

    const handleResize = (event: any, { size }: { size: { width: number; height: number } }) => {
        setChartSize(size);
    };

    const toggleDropdownVisibility = (key: keyof typeof dropdownVisibility) => {
        setDropdownVisibility((prev) => ({
            ...prev,
            [key]: !prev[key],
        }));
    };

    const toggleAllDropdowns = () => {
        const isVisible = Object.values(dropdownVisibility).some((visible) => visible);
        const newState = Object.keys(dropdownVisibility).reduce(
            (acc, key) => ({ ...acc, [key]: !isVisible }),
            {}
        ) as typeof dropdownVisibility;

        setDropdownVisibility(newState);
    };

    const handleAddFilter = async (field: string) => {
        if (!dimensionValues[field]) {
            const values = Array.from(new Set(data.map((item) => item[field] || "")));
            setDimensionValues((prev) => ({ ...prev, [field]: values }));
        }
        setFilters((prev) => [...prev, { field, value: "All" }]);
    };

    const handleFilterChange = (field: string, value: string) => {
        setFilters((prev) =>
            prev.map((filter) => (filter.field === field ? { ...filter, value } : filter))
        );
    };

    const handleRemoveFilter = (field: string) => {
        setFilters((prev) => prev.filter((filter) => filter.field !== field));
    };

    const applyFilters = (data: any[]) => {
        return data
            .filter((item) =>
                filters.every((filter) =>
                    filter.value === "All" || item[filter.field] === filter.value
                )
            )
            .filter((item) => (keyword ? JSON.stringify(item).includes(keyword) : true))
            .slice(0, topN || data.length);
    };

    const filteredData = applyFilters(data);

    const config = {
        data: filteredData,
        angleField,
        colorField,
        appendPadding: [20, 20, 20, 20],
        autoFit: false,
        radius: 0.8,
        width: chartSize.width - 20,
        height: chartSize.height - 60,
    };

    return (
        <div
            onMouseEnter={() => setHovered(true)}
            onMouseLeave={() => setHovered(false)}
            style={{
                position: "relative",
                marginBottom: 20,
                padding: "10px",
                borderRadius: "8px",
                backgroundColor: "#fff",
                transition: "box-shadow 0.3s",
                boxShadow: hovered ? "0 4px 8px rgba(0, 0, 0, 0.2)" : "none",
            }}
        >
            <Button onClick={toggleAllDropdowns} style={{ marginBottom: 16 }}>
                {Object.values(dropdownVisibility).some((visible) => visible) ? (
                    <EyeInvisibleOutlined />
                ) : (
                    <EyeOutlined />
                )}
            </Button>

            <ResizableBox
                width={chartSize.width}
                height={chartSize.height}
                minConstraints={[100, 100]}
                maxConstraints={[800, 800]}
                resizeHandles={hovered ? ["se", "e", "s"] : []}
                onResizeStop={handleResize}
                style={{ width: "100%", height: "100%" }}
            >
                <div>
                    <div style={{ display: "flex", alignItems: "center", marginBottom: 10 }}>
                        {editingTitle ? (
                            <Input
                                value={title}
                                onChange={(e) => setTitle(e.target.value)}
                                onBlur={() => setEditingTitle(false)}
                                autoFocus
                                style={{
                                    fontSize: "16px",
                                    fontWeight: "bold",
                                    border: "none",
                                    padding: 0,
                                }}
                                placeholder="Enter chart title"
                            />
                        ) : (
                            <Tooltip title="Click to edit the chart title">
                                <div
                                    style={{
                                        fontSize: "16px",
                                        fontWeight: "bold",
                                        cursor: "pointer",
                                        display: "flex",
                                        alignItems: "center",
                                    }}
                                    onClick={() => setEditingTitle(true)}
                                >
                                    {title}
                                    <EditOutlined style={{ marginLeft: 8 }} />
                                </div>
                            </Tooltip>
                        )}
                    </div>

                    {dropdownVisibility.angleField && (
                        <Select
                            style={{ width: "100%", marginBottom: 16 }}
                            placeholder="Select Angle Field"
                            value={angleField}
                            onChange={(value) => setAngleField(value)}
                        >
                            {keys.map((key) => (
                                <Option key={key} value={key}>
                                    {key}
                                </Option>
                            ))}
                        </Select>
                    )}
                    <Tooltip title="Show/Hide Angle Field">
                        <Button
                            type="link"
                            icon={
                                dropdownVisibility.angleField ? (
                                    <EyeInvisibleOutlined />
                                ) : (
                                    <EyeOutlined />
                                )
                            }
                            onClick={() => toggleDropdownVisibility("angleField")}
                        />
                    </Tooltip>

                    {dropdownVisibility.colorField && (
                        <Select
                            style={{ width: "100%", marginBottom: 16 }}
                            placeholder="Select Color Field"
                            value={colorField}
                            onChange={(value) => setColorField(value)}
                        >
                            {keys.map((key) => (
                                <Option key={key} value={key}>
                                    {key}
                                </Option>
                            ))}
                        </Select>
                    )}
                    <Tooltip title="Show/Hide Color Field">
                        <Button
                            type="link"
                            icon={
                                dropdownVisibility.colorField ? (
                                    <EyeInvisibleOutlined />
                                ) : (
                                    <EyeOutlined />
                                )
                            }
                            onClick={() => toggleDropdownVisibility("colorField")}
                        />
                    </Tooltip>

                    {dropdownVisibility.filters && (
                        <div style={{ marginBottom: 16 }}>
                            {filters.map((filter) => (
                                <Row key={filter.field} gutter={[8, 8]} style={{ marginBottom: 8 }}>
                                    <Col flex="auto">
                                        <Select
                                            style={{ width: "100%" }}
                                            placeholder={`Filter: ${filter.field}`}
                                            value={filter.value}
                                            onChange={(value) => handleFilterChange(filter.field, value)}
                                        >
                                            {(dimensionValues[filter.field] || []).map((value) => (
                                                <Option key={value} value={value}>
                                                    {value}
                                                </Option>
                                            ))}
                                        </Select>
                                    </Col>
                                    <Col>
                                        <Button
                                            danger
                                            size="small"
                                            onClick={() => handleRemoveFilter(filter.field)}
                                        >
                                            Delete
                                        </Button>
                                    </Col>
                                </Row>
                            ))}
                            <Select
                                style={{ width: "100%" }}
                                placeholder="Add Filter"
                                onChange={(field: any) => handleAddFilter(field)}
                                value={undefined}
                            >
                                {keys
                                    .filter((key) => !filters.find((filter) => filter.field === key))
                                    .map((key) => (
                                        <Option key={key} value={key}>
                                            {key}
                                        </Option>
                                    ))}
                            </Select>
                        </div>
                    )}
                    <Tooltip title="Show/Hide Filters">
                        <Button
                            type="link"
                            icon={
                                dropdownVisibility.filters ? (
                                    <EyeInvisibleOutlined />
                                ) : (
                                    <EyeOutlined />
                                )
                            }
                            onClick={() => toggleDropdownVisibility("filters")}
                        />
                    </Tooltip>

                    {dropdownVisibility.keyword && (
                        <Input
                            placeholder="Search by keyword"
                            value={keyword}
                            onChange={(e) => setKeyword(e.target.value)}
                            style={{ width: "100%", marginBottom: 16 }}
                        />
                    )}
                    <Tooltip title="Show/Hide Keyword Search">
                        <Button
                            type="link"
                            icon={
                                dropdownVisibility.keyword ? (
                                    <EyeInvisibleOutlined />
                                ) : (
                                    <EyeOutlined />
                                )
                            }
                            onClick={() => toggleDropdownVisibility("keyword")}
                        />
                    </Tooltip>

                    {dropdownVisibility.topN && (
                        <InputNumber
                            placeholder="Top N"
                            min={1}
                            value={topN || undefined}
                            onChange={(value) => setTopN(value)}
                            style={{ width: "100%", marginBottom: 16 }}
                        />
                    )}
                    <Tooltip title="Show/Hide Top N">
                        <Button
                            type="link"
                            icon={
                                dropdownVisibility.topN ? (
                                    <EyeInvisibleOutlined />
                                ) : (
                                    <EyeOutlined />
                                )
                            }
                            onClick={() => toggleDropdownVisibility("topN")}
                        />
                    </Tooltip>

                    <Pie {...config} />
                </div>
            </ResizableBox>
        </div>
    );
};

export default ResizablePieChart;
