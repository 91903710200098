import React, { useState } from "react";
import { Row, Col, Spin, Alert, Button, Card, Divider, Input, Tooltip } from "antd";
import { ResizableBox } from "react-resizable";
import { DeleteOutlined, EyeInvisibleOutlined, EyeOutlined, EditOutlined } from "@ant-design/icons";
import "react-resizable/css/styles.css";
import LineChart from "./monitoringdashboard/charts/LineChart";
import BarChart from "./monitoringdashboard/charts/BarChart";
import PieChart from "./monitoringdashboard/charts/PieChart";
import AreaChart from "./monitoringdashboard/charts/AreaChart";
import DataTable from "./monitoringdashboard/DataTable";
import KPIChart from "./monitoringdashboard/charts/kpiCard";
import FunnelChart from "./monitoringdashboard/charts/FunnelChart";
import ProgressChart from "./monitoringdashboard/charts/ProgressChart";
import DonutChart from "./monitoringdashboard/charts/DonutChart";
import ExpandablePanel from "./monitoringdashboard/charts/ExpandablePanel";
//import GeospatialMap from "./monitoringdashboard/charts/GeospatialMap";
//import InteractFilters from "./monitoringdashboard/charts/InteractFilters";
import RealTimeUpdate from "./monitoringdashboard/charts/RealTimeUpdate";
import SankeyDiagram from "./monitoringdashboard/charts/SankeyDiagram";
import Sparkline from "./monitoringdashboard/charts/Sparkline";
import CubeSelector from "./monitoringdashboard/CubeSelector";
import MeasureDimensionSelector from "./monitoringdashboard/MeasureDimensionSelector";
import ChartVisibilitySelector from "./monitoringdashboard/ChartVisibilitySelector";

interface DashboardCardProps {
    id: string;
    meta: any[];
    selectedCubes: string[];
    measures: any[];
    dimensions: any[];
    selectedMeasures: string[];
    selectedDimensions: string[];
    selectedFilters: any[];
    data: any[];
    chartVisibility: string[];
    availableKeys: string[];
    error: string | null;
    loading: boolean;
    fetchDimensionValues: (dimension: string) => Promise<string[]>; // Add this line
    onUpdate: (updates: Partial<DashboardCardProps>) => void;
    onRemove: () => void;
    onFetchData: () => void;
}
const DashboardCard: React.FC<DashboardCardProps> = ({
    id,
    meta,
    selectedCubes,
    measures,
    dimensions,
    selectedMeasures,
    selectedDimensions,
    selectedFilters,
    availableKeys,
    data,
    chartVisibility,
    error,
    loading,
    fetchDimensionValues,
    onUpdate,
    onRemove,
    onFetchData,
}) => {
    const [cardSize, setCardSize] = useState({ width: 600, height: 400 }); // Initial size
    const [controlsVisible, setControlsVisible] = useState(true); // Manage visibility of controls
    const [title, setTitle] = useState(`Dynamic Dashboard Builder - ${id}`); // Editable title
    const [editingTitle, setEditingTitle] = useState(false); // Whether the title is being edited

    const handleResize = (event: any, { size }: { size: { width: number; height: number } }) => {
        setCardSize(size);
    };
    const exportState = () => ({
        id,
        cardSize,
        controlsVisible,
        title,
        editingTitle,
        selectedCubes,
        measures,
        dimensions,
        selectedMeasures,
        selectedDimensions,
        selectedFilters,
        chartVisibility,
        data,
        error,
        loading,
    });

    // Import and apply a state
    const importState = (state: any) => {
        setCardSize(state.cardSize);
        setControlsVisible(state.controlsVisible);
        setTitle(state.title);
        setEditingTitle(state.editingTitle);
        onUpdate({
            selectedCubes: state.selectedCubes,
            measures: state.measures,
            dimensions: state.dimensions,
            selectedMeasures: state.selectedMeasures,
            selectedDimensions: state.selectedDimensions,
            selectedFilters: state.selectedFilters,
            chartVisibility: state.chartVisibility,
        });
    };

    return (
        <ResizableBox
            width={cardSize.width}
            height={cardSize.height}
            minConstraints={[200, 100]} // Minimum width and height
            maxConstraints={[3000, 3000]} // Maximum width and height
            resizeHandles={["se", "e", "s"]}
            onResize={handleResize}
            style={{ margin: 0 }}
        >
            <Card
                title={
                    <div style={{ display: "flex", alignItems: "center" }}>
                        {editingTitle ? (
                            <Input
                                value={title}
                                onChange={(e) => setTitle(e.target.value)} // Update the title on input change
                                onBlur={() => setEditingTitle(false)} // Stop editing when input loses focus
                                autoFocus
                                style={{
                                    fontSize: "16px",
                                    fontWeight: "bold",
                                    padding: 0,
                                }}
                                placeholder="Enter dashboard title"
                            />
                        ) : (
                            <Tooltip title="Click to edit the dashboard title">
                                <div
                                    style={{ display: "flex", alignItems: "center", cursor: "pointer" }}
                                    onClick={() => setEditingTitle(true)} // Enable editing on click
                                >
                                    {title}
                                    <EditOutlined style={{ marginLeft: 8 }} />
                                </div>
                            </Tooltip>
                        )}
                    </div>
                }
                extra={
                    <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
                        <Tooltip title="Remove Dashboard">
                            <Button
                                type="text"
                                icon={<DeleteOutlined />}
                                onClick={onRemove}
                                style={{ color: "red" }}
                            />
                        </Tooltip>
                        <Tooltip title={controlsVisible ? "Hide Controls" : "Show Controls"}>
                            <Button
                                type="text"
                                icon={controlsVisible ? <EyeInvisibleOutlined /> : <EyeOutlined />}
                                onClick={() => setControlsVisible(!controlsVisible)}
                            />
                        </Tooltip>
                    </div>
                }
                style={{
                    width: "100%",
                    height: "100%",
                    overflow: "hidden", // Ensure content fits within the resized card
                }}
            >
                {error && (
                    <Alert message={error} type="error" showIcon closable style={{ marginBottom: 20 }} />
                )}
                {controlsVisible && (
                    <>
                        <Row gutter={[16, 16]}>
                            <Col span={6}>
                                <CubeSelector
                                    meta={meta}
                                    onChange={(cubeNames) => {
                                        const selectedMeta = meta.filter((cube) =>
                                            cubeNames.includes(cube.name)
                                        );
                                        onUpdate({
                                            selectedCubes: cubeNames,
                                            measures: selectedMeta.flatMap((cube) => cube.measures || []),
                                            dimensions: selectedMeta.flatMap((cube) => cube.dimensions || []),
                                            selectedMeasures: [],
                                            selectedDimensions: [],
                                        });
                                    }}
                                />
                            </Col>
                            <Col span={6}>
                                <MeasureDimensionSelector
                                    measures={measures}
                                    dimensions={dimensions}
                                    selectedMeasures={selectedMeasures}
                                    selectedDimensions={selectedDimensions}
                                    selectedFilters={selectedFilters}
                                    setSelectedMeasures={(measures) => onUpdate({ selectedMeasures: measures })}
                                    setSelectedDimensions={(dimensions) => onUpdate({ selectedDimensions: dimensions })}
                                    setSelectedFilters={(filters) => onUpdate({ selectedFilters: filters })}
                                    fetchDimensionValues={fetchDimensionValues}
                                />
                            </Col>
                            <Col span={12}>
                                <ChartVisibilitySelector
                                    chartVisibility={chartVisibility}
                                    setChartVisibility={(visibility) => onUpdate({ chartVisibility: visibility })}
                                />
                            </Col>
                        </Row>
                        <Divider />
                        <Row justify="center">
                            <Button type="primary" onClick={onFetchData}>
                                Fetch Data
                            </Button>
                        </Row>
                    </>
                )}
                {loading ? (
                    <Spin size="large" style={{ display: "block", textAlign: "center", marginTop: 50 }} />
                ) : (
                    <Row gutter={[16, 16]} style={{ marginTop: 20 }}>
                        {chartVisibility.includes("line") && (
                            <Col span={12}>
                                <LineChart
                                    data={data}
                                    availableKeys={availableKeys}
                                    fetchDimensionValues={fetchDimensionValues}
                                    defaultXField={selectedDimensions[0] || ""}
                                    defaultYField={selectedMeasures[0] || ""}
                                />
                            </Col>
                        )}
                        {chartVisibility.includes("bar") && (
                            <Col span={12}>
                                <BarChart
                                    data={data}
                                    availableKeys={availableKeys}
                                    fetchDimensionValues={fetchDimensionValues}
                                    defaultXField={selectedDimensions[0] || ""}
                                    defaultYField={selectedMeasures[0] || ""}
                                />
                            </Col>
                        )}
                        {chartVisibility.includes("pie") && (
                            <Col span={12}>
                                <PieChart
                                    data={data}
                                    availableKeys={availableKeys}
                                    defaultAngleField={selectedMeasures[0] || ""}
                                    defaultColorField={selectedDimensions[0] || ""}
                                />
                            </Col>
                        )}
                        {chartVisibility.includes("area") && (
                            <Col span={12}>
                                <AreaChart
                                    data={data}
                                    availableKeys={availableKeys}
                                    fetchDimensionValues={fetchDimensionValues}
                                    defaultXField={selectedDimensions[0] || ""}
                                    defaultYField={selectedMeasures[0] || ""}
                                />
                            </Col>
                        )}
                        {chartVisibility.includes("kpi") && (
                            <Col span={12}>
                                <KPIChart value="123" label="Sample KPI" />
                            </Col>
                        )}
                        {chartVisibility.includes("funnel") && (
                            <Col span={12}>
                                <FunnelChart
                                    data={data}
                                    availableKeys={availableKeys}
                                    fetchDimensionValues={fetchDimensionValues}
                                    defaultXField={selectedDimensions[0] || ""}
                                    defaultYField={selectedMeasures[0] || ""}
                                />
                            </Col>
                        )}
                        {/* {chartVisibility.includes("progress") && (
                            <Col span={12}>
                                <ProgressChart progress={75} />
                            </Col>
                        )} */}
                        {chartVisibility.includes("donut") && (
                            <Col span={12}>
                                <DonutChart
                                    data={data}
                                    availableKeys={availableKeys}
                                    fetchDimensionValues={fetchDimensionValues}
                                    defaultAngleField={selectedMeasures[0] || ""}
                                    defaultColorField={selectedDimensions[0] || ""}
                                />
                            </Col>
                        )}
                        {/* {chartVisibility.includes("geospatial") && (
                            <Col span={24}>
                                <GeospatialMap markers={[]} />
                            </Col>
                        )}
                        {chartVisibility.includes("interactiveFilters") && (
                            <Col span={24}>
                                <InteractFilters onFilterChange={() => { }} />
                            </Col>
                        )} */}
                        {chartVisibility.includes("realTime") && (
                            <Col span={24}>
                                <RealTimeUpdate initialData={[]} socketUrl="wss://example.com" />
                            </Col>
                        )}
                        {chartVisibility.includes("sankey") && (
                            <Col span={24}>
                                <SankeyDiagram
                                    data={data}
                                    sourceField="source"
                                    targetField="target"
                                    valueField="value"
                                />
                            </Col>
                        )}
                        {chartVisibility.includes("sparkline") && (
                            <Col span={12}>
                                <Sparkline
                                    data={data}
                                    xField={selectedDimensions[0] || ""}
                                    yField={selectedMeasures[0] || ""}
                                />
                            </Col>
                        )}
                        {chartVisibility.includes("table") && (
                            <Col span={24}>
                                <DataTable
                                    data={data}
                                    columns={[
                                        ...selectedDimensions.map((dim) => ({
                                            title: dim,
                                            dataIndex: dim,
                                            key: dim,
                                        })),
                                        ...selectedMeasures.map((measure) => ({
                                            title: measure,
                                            dataIndex: measure,
                                            key: measure,
                                        })),
                                    ]}
                                    rowKey={(record: any) => record[selectedDimensions[0] || "id"]}
                                />
                            </Col>
                        )}
                    </Row>
                )}
            </Card>
        </ResizableBox>
    );
};

export default DashboardCard;
