import React, { useEffect, useState, useRef } from "react";
import mermaid from "mermaid";
import { Typography, Box } from "@mui/material";

interface FlowViewerProps {
    mainFlows: any[]; // Array of main flows passed as props
}

const FlowViewer = ({ mainFlows }: FlowViewerProps) => {
    const [currentFlow, setCurrentFlow] = useState<any>(null);
    const [selectedMainFlowId, setSelectedMainFlowId] = useState<number | null>(null);
    const [flowHistory, setFlowHistory] = useState<any[]>([]); // Stack to maintain flow history
    const mermaidRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        // Initialize with the first main flow
        if (mainFlows.length > 0) {
            setSelectedMainFlowId(mainFlows[0].id);
            setCurrentFlow(mainFlows[0]);
        }
    }, [mainFlows]);

    const generateMermaidDiagram = (flow: string, subFlowMapping: Record<string, number>): string => {
        const lines = flow.split("\n").filter(Boolean);
        const graphDefinition = ["graph TD"];

        // Handle the first two nodes (`A` and `B`) specially
        if (lines.length > 1) {
            const firstLineMatch = lines[1].match(/(\w+)\[(.+?)\] --> (\w+)\[(.+?)\]/);
            if (firstLineMatch) {
                const [, nodeIdA, labelA, nodeIdB, labelB] = firstLineMatch;

                // Add the first node as an entry point
                graphDefinition.push(`${nodeIdA}["${labelA}"]`);
                graphDefinition.push(`${nodeIdA} --> ${nodeIdB}`);
                graphDefinition.push(`${nodeIdB}["${labelB}"]`);
            }
        }

        // Process the remaining lines (standard nodes and edges)
        for (let i = 2; i < lines.length; i++) {
            const line = lines[i];
            const edgeMatch = line.match(/(\w+) --> (\w+)\[(.+?)\]/); // Match edge definitions
            if (edgeMatch) {
                const [, source, target, label] = edgeMatch;

                // Add the target node and its connection
                graphDefinition.push(`${source} --> ${target}`);
                graphDefinition.push(`${target}["${label}"]`);
            }
        }

        // Highlight nodes with sub-flows
        for (const nodeId in subFlowMapping) {
            graphDefinition.push(
                `style ${nodeId} stroke:#28a745,stroke-width:2px,fill:#f9fff9,stroke-dasharray:5,5;`
            );
        }

        return graphDefinition.join("\n");
    };

    useEffect(() => {
        if (currentFlow && mermaidRef.current) {
            const mermaidGraph = generateMermaidDiagram(currentFlow.flow, currentFlow.subFlowMapping);
            try {
                mermaid.render("graphDiv", mermaidGraph).then(({ svg }) => {
                    if (mermaidRef.current) {
                        mermaidRef.current.innerHTML = svg;
                    }
                }).catch((error) => {
                    console.error("Mermaid rendering error:", error);
                });
            } catch (error) {
                console.error("Mermaid rendering error:", error);
            }
        }
    }, [currentFlow]);

    if (!currentFlow) {
        return <Typography>No flow data available.</Typography>;
    }

    return (
        <Box
            sx={{
                height: "50vh",
                width: "50vw",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                overflow: "hidden", // Prevent extra scroll

            }}
        >
            <div
                ref={mermaidRef}
                style={{
                    transform: "scale(0.5)", // Scale down the graph
                    transformOrigin: "center", // Keep scaling centered
                    width: "50%", // Allow the graph to use full container width
                    marginTop: "-30px", // Add some space at the bottom
                    overflow: "hidden",


                }}
            />
        </Box>
    );
};

export default FlowViewer;
