import { Node, Edge } from "reactflow";
import { parseMainFlow } from "./parseMainFlow";
import { parseSubFlow } from "./SubflowParsing";
import dagre from "dagre";

const nodeWidth = 200;
const nodeHeight = 50;

const dagreGraph = new dagre.graphlib.Graph();
dagreGraph.setDefaultEdgeLabel(() => ({}));

dagreGraph.setGraph({
    rankdir: "TB", // Top to Bottom
    align: "UL", // Left-align nodes vertically
    nodesep: 50, // Vertical space between nodes
    ranksep: 100, // Horizontal space between ranks
});

export const combineFlows = (
    mainFlow: string,
    subFlowMapping: Record<string, number>,
    subFlows: any[]
): { nodes: Node[]; edges: Edge[] } => {
    const { nodes: mainNodes, edges: mainEdges } = parseMainFlow(mainFlow);
    const combinedNodes = [...mainNodes];
    const combinedEdges = [...mainEdges];

    mainNodes.forEach((mainNode: Node, index: number) => {
        const subFlowId = subFlowMapping[mainNode.id];
        if (subFlowId) {
            const subFlow = subFlows.find((flow) => flow.id === subFlowId);
            if (subFlow) {
                const { nodes: subNodes, edges: subEdges } = parseSubFlow(
                    subFlow.flow,
                    mainNode.id,
                    index + 1, // Increment color index for each subflow
                    { x: mainNode.position.x, y: mainNode.position.y + 150 }
                );

                if (subNodes.length > 0) {
                    combinedEdges.push({
                        id: `${mainNode.id}-to-${subNodes[0].id}`,
                        source: mainNode.id,
                        target: subNodes[0].id,
                        animated: true,
                        style: { stroke: "black", strokeDasharray: "5,5", strokeWidth: 2 },
                    });
                }

                combinedNodes.push(...subNodes);
                combinedEdges.push(...subEdges);
            }
        }
    });

    // Use dagre to layout nodes vertically
    combinedNodes.forEach((node) => {
        dagreGraph.setNode(node.id, { width: nodeWidth, height: nodeHeight });
    });

    combinedEdges.forEach((edge) => {
        dagreGraph.setEdge(edge.source, edge.target);
    });

    dagre.layout(dagreGraph);

    // Update node positions based on dagre layout
    combinedNodes.forEach((node) => {
        const { x, y } = dagreGraph.node(node.id);
        node.position = {
            x: x - nodeWidth / 2,
            y: y - nodeHeight / 2,
        };
    });

    return { nodes: combinedNodes, edges: combinedEdges };
};
