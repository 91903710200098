import React, { useState } from "react";
import { Card, Input, Tooltip } from "antd";
import { EditOutlined } from "@ant-design/icons";
import { ResizableBox } from "react-resizable";
import "react-resizable/css/styles.css";

interface KPIProps {
    value: string | number;
    label: string;
}

const ResizableKPI: React.FC<KPIProps> = ({ value, label }) => {
    const [boxSize, setBoxSize] = useState({ width: 200, height: 100 }); // Default size
    const [title, setTitle] = useState(label); // Editable label
    const [editingTitle, setEditingTitle] = useState(false); // Whether the title is being edited
    const [hovered, setHovered] = useState(false); // Hover state

    const handleResize = (event: any, { size }: { size: { width: number; height: number } }) => {
        setBoxSize(size);
    };

    return (
        <div
            onMouseEnter={() => setHovered(true)}
            onMouseLeave={() => setHovered(false)}
            style={{
                position: "relative",
                marginBottom: 20,
                padding: "10px",
                borderRadius: "8px",
                backgroundColor: "#fff",
                transition: "box-shadow 0.3s",
                boxShadow: hovered ? "0 4px 8px rgba(0, 0, 0, 0.2)" : "none",
            }}
        >
            <ResizableBox
                width={boxSize.width}
                height={boxSize.height}
                minConstraints={[150, 80]} // Minimum width and height
                maxConstraints={[400, 200]} // Maximum width and height
                resizeHandles={hovered ? ["se", "e", "s"] : []} // Show handles only on hover
                onResizeStop={handleResize}
                style={{
                    width: "100%",
                    height: "100%",
                }}
            >
                <Card
                    style={{
                        width: "100%",
                        height: "100%",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        overflow: "hidden",
                    }}
                >
                    {editingTitle ? (
                        <Input
                            value={title}
                            onChange={(e) => setTitle(e.target.value)} // Update label
                            onBlur={() => setEditingTitle(false)} // Stop editing on blur
                            autoFocus
                            style={{
                                fontSize: "14px",
                                fontWeight: "bold",
                                textAlign: "center",
                                marginBottom: 8,
                            }}
                        />
                    ) : (
                        <Tooltip title="Click to edit the KPI label">
                            <div
                                style={{
                                    fontSize: "14px",
                                    fontWeight: "bold",
                                    textAlign: "center",
                                    marginBottom: 8,
                                    cursor: "pointer",
                                }}
                                onClick={() => setEditingTitle(true)}
                            >
                                {title} <EditOutlined style={{ marginLeft: 8 }} />
                            </div>
                        </Tooltip>
                    )}
                    <div style={{ fontSize: "24px", fontWeight: "bold" }}>{value}</div>
                </Card>
            </ResizableBox>
        </div>
    );
};

export default ResizableKPI;
