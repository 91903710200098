import React from "react";
import ReactFlow, { Background, Controls, Node } from "reactflow";
import { combineFlows } from "./combineFlows";
import { Box } from "@mui/material";

interface FlowGraphProps {
    currentFlow: any; // Replace with specific type if available
    subFlows: any[];  // Replace with specific type if available
    setCurrentFlow: (flow: any) => void; // Function to update the current flow
    setFlowHistory: React.Dispatch<React.SetStateAction<any[]>>; // Update flow history
}

const FlowGraph = ({ currentFlow, subFlows, setCurrentFlow, setFlowHistory }: FlowGraphProps) => {
    if (!currentFlow) return null;

    const { nodes, edges } = combineFlows(currentFlow.flow, currentFlow.subFlowMapping || {}, subFlows);

    const handleNodeClick = (_: React.MouseEvent, node: Node) => {
        const subFlowId = currentFlow.subFlowMapping[node.id];
        if (subFlowId) {
            const subFlow = subFlows.find((flow) => flow.id === subFlowId);
            if (subFlow) {
                setFlowHistory((prev) => [...prev, currentFlow]); // Add current flow to history
                setCurrentFlow(subFlow); // Switch to the subflow
            }
        }
    };

    return (
        <Box sx={{ flex: 1, padding: 2, border: "1px solid lightgray", borderRadius: "8px" }}>
            <ReactFlow
                nodes={nodes}
                edges={edges}
                fitView
                onNodeClick={handleNodeClick} // Attach the click handler
            >
                <Background gap={16} size={1} />
                <Controls />
            </ReactFlow>
        </Box>
    );
};

export default FlowGraph;
