import React, { useState, useEffect } from "react";
import { Pie } from "@ant-design/charts";
import { ResizableBox } from "react-resizable";
import { Input, Tooltip, Select, Row, Col, Button, InputNumber, DatePicker } from "antd";
import { EditOutlined, EyeInvisibleOutlined, EyeOutlined } from "@ant-design/icons";
import { groupBy, sumBy, meanBy, countBy } from "lodash";
import moment from "moment";
import "react-resizable/css/styles.css";

const { Option } = Select;
const { RangePicker } = DatePicker;

interface DonutChartProps {
    data: any[];
    availableKeys?: string[];
    fetchDimensionValues: (dimension: string) => Promise<string[]>;
    defaultAngleField: string;
    defaultColorField: string;
}

const ResizableDonutChart: React.FC<DonutChartProps> = ({
    data,
    availableKeys,
    fetchDimensionValues,
    defaultAngleField,
    defaultColorField,
}) => {
    const [chartSize, setChartSize] = useState({ width: 400, height: 400 });
    const [title, setTitle] = useState("Resizable Donut Chart");
    const [editingTitle, setEditingTitle] = useState(false);
    const [hovered, setHovered] = useState(false);

    const [angleField, setAngleField] = useState<string>(defaultAngleField);
    const [colorField, setColorField] = useState<string>(defaultColorField);
    const [filters, setFilters] = useState<{ dimension: string; value: string }[]>([]);
    const [dimensionValues, setDimensionValues] = useState<Record<string, string[]>>({});
    const [aggregation, setAggregation] = useState<string>("sum");
    const [dateRange, setDateRange] = useState<[moment.Moment | null, moment.Moment | null] | null>(null);
    const [keyword, setKeyword] = useState<string>("");
    const [topN, setTopN] = useState<number | null>(null);

    const [dropdownVisibility, setDropdownVisibility] = useState({
        angleField: false,
        colorField: false,
        filters: false,
        dateRange: false,
        keyword: false,
        topN: false,
        groupBy: false,
    });

    const keys = availableKeys || (data.length > 0 ? Object.keys(data[0]) : []);

    useEffect(() => {
        if (!keys.includes(angleField)) setAngleField(keys[0]);
        if (!keys.includes(colorField)) setColorField(keys[1]);
    }, [keys, angleField, colorField]);

    const handleResize = (event: any, { size }: { size: { width: number; height: number } }) => {
        setChartSize(size);
    };

    const handleAddFilter = async (dimension: string) => {
        if (!dimensionValues[dimension]) {
            const values = await fetchDimensionValues(dimension);
            setDimensionValues((prev) => ({
                ...prev,
                [dimension]: ["All", ...values || []],
            }));
        }
        setFilters((prev) => [...prev, { dimension, value: "All" }]);
    };

    const handleFilterChange = (dimension: string, value: string) => {
        setFilters((prev) =>
            prev.map((filter) =>
                filter.dimension === dimension ? { ...filter, value } : filter
            )
        );
    };

    const handleRemoveFilter = (dimension: string) => {
        setFilters((prev) => prev.filter((filter) => filter.dimension !== dimension));
    };

    const toggleDropdownVisibility = (key: keyof typeof dropdownVisibility) => {
        setDropdownVisibility((prev) => ({
            ...prev,
            [key]: !prev[key],
        }));
    };

    const toggleAllDropdowns = () => {
        const isVisible = Object.values(dropdownVisibility).some((visible) => visible);
        const newState = Object.keys(dropdownVisibility).reduce(
            (acc, key) => ({ ...acc, [key]: !isVisible }),
            {}
        ) as typeof dropdownVisibility;

        setDropdownVisibility(newState);
    };

    const applyGroupBy = (data: any[]) => {
        if (!angleField) return data;

        const grouped = groupBy(data, colorField);
        return Object.keys(grouped).map((key) => {
            const group = grouped[key];
            switch (aggregation) {
                case "sum":
                    return {
                        [colorField]: key,
                        [angleField]: sumBy(group, angleField),
                    };
                case "average":
                    return {
                        [colorField]: key,
                        [angleField]: meanBy(group, angleField),
                    };
                case "count":
                    return {
                        [colorField]: key,
                        [angleField]: group.length,
                    };
                default:
                    return {};
            }
        });
    };

    const filteredData = applyGroupBy(
        data
            .filter((item) =>
                filters.every(
                    (filter) =>
                        filter.value === "All" || item[filter.dimension] === filter.value
                )
            )
            .filter((item) =>
                !dateRange || (dateRange[0] && dateRange[1] && item.date >= dateRange[0] && item.date <= dateRange[1])
            )
            .filter((item) => (keyword ? JSON.stringify(item).includes(keyword) : true))
            .slice(0, topN || data.length)
    );

    const config = {
        data: filteredData,
        angleField,
        colorField,
        appendPadding: [20, 20, 20, 20],
        autoFit: false,
        width: chartSize.width - 40,
        height: chartSize.height - 40,
        radius: 0.8,
        innerRadius: 0.6,
        label: {
            type: "outer",
            labelHeight: 28,
            content: "{name}\n{percentage}",
        },
    };

    return (
        <div
            onMouseEnter={() => setHovered(true)}
            onMouseLeave={() => setHovered(false)}
            style={{
                position: "relative",
                marginBottom: 20,
                padding: "10px",
                borderRadius: "8px",
                backgroundColor: "#fff",
                transition: "box-shadow 0.3s",
                boxShadow: hovered ? "0 4px 8px rgba(0, 0, 0, 0.2)" : "none",
            }}
        >
            <Button onClick={toggleAllDropdowns} style={{ marginBottom: 16 }}>
                {Object.values(dropdownVisibility).some((visible) => visible) ? (
                    <EyeInvisibleOutlined />
                ) : (
                    <EyeOutlined />
                )}
            </Button>

            <ResizableBox
                width={chartSize.width}
                height={chartSize.height}
                minConstraints={[200, 200]}
                maxConstraints={[800, 800]}
                resizeHandles={hovered ? ["se", "e", "s"] : []}
                onResizeStop={handleResize}
                style={{ width: "100%", height: "100%" }}
            >
                <div>
                    <div style={{ display: "flex", alignItems: "center", marginBottom: 10 }}>
                        {editingTitle ? (
                            <Input
                                value={title}
                                onChange={(e) => setTitle(e.target.value)}
                                onBlur={() => setEditingTitle(false)}
                                autoFocus
                                style={{ fontSize: "16px", fontWeight: "bold", border: "none", padding: 0 }}
                                placeholder="Enter chart title"
                            />
                        ) : (
                            <Tooltip title="Click to edit the chart title">
                                <div
                                    style={{
                                        fontSize: "16px",
                                        fontWeight: "bold",
                                        cursor: "pointer",
                                        display: "flex",
                                        alignItems: "center",
                                    }}
                                    onClick={() => setEditingTitle(true)}
                                >
                                    {title}
                                    <EditOutlined style={{ marginLeft: 8 }} />
                                </div>
                            </Tooltip>
                        )}
                    </div>

                    {dropdownVisibility.angleField && (
                        <Row gutter={[16, 16]} style={{ marginBottom: 16 }}>
                            <Col span={12}>
                                <Select
                                    style={{ width: "100%" }}
                                    placeholder="Select Angle Field"
                                    value={angleField}
                                    onChange={(value) => setAngleField(value)}
                                    allowClear
                                >
                                    {keys.map((key) => (
                                        <Option key={key} value={key}>
                                            {key}
                                        </Option>
                                    ))}
                                </Select>
                            </Col>
                            <Col span={12}>
                                <Select
                                    style={{ width: "100%" }}
                                    placeholder="Select Color Field"
                                    value={colorField}
                                    onChange={(value) => setColorField(value)}
                                    allowClear
                                >
                                    {keys.map((key) => (
                                        <Option key={key} value={key}>
                                            {key}
                                        </Option>
                                    ))}
                                </Select>
                            </Col>
                        </Row>
                    )}

                    <Tooltip title="Show/Hide Angle and Color Fields">
                        <Button
                            type="link"
                            icon={
                                dropdownVisibility.angleField ? (
                                    <EyeInvisibleOutlined />
                                ) : (
                                    <EyeOutlined />
                                )
                            }
                            onClick={() => toggleDropdownVisibility("angleField")}
                        />
                    </Tooltip>

                    {dropdownVisibility.filters && (
                        <div style={{ marginBottom: 16 }}>
                            {filters.map((filter) => (
                                <Row key={filter.dimension} gutter={[8, 8]} style={{ marginBottom: 8 }}>
                                    <Col flex="auto">
                                        <Select
                                            style={{ width: "100%" }}
                                            placeholder={`Filter: ${filter.dimension}`}
                                            value={filter.value}
                                            onChange={(value) => handleFilterChange(filter.dimension, value)}
                                        >
                                            {(dimensionValues[filter.dimension] || []).map((value) => (
                                                <Option key={value} value={value}>
                                                    {value}
                                                </Option>
                                            ))}
                                        </Select>
                                    </Col>
                                    <Col>
                                        <Button
                                            danger
                                            size="small"
                                            onClick={() => handleRemoveFilter(filter.dimension)}
                                        >
                                            Delete
                                        </Button>
                                    </Col>
                                </Row>
                            ))}
                            <Select
                                style={{ width: "100%" }}
                                placeholder="Add Filter"
                                onChange={handleAddFilter}
                                value={undefined}
                            >
                                {keys
                                    .filter((key) => !filters.find((filter) => filter.dimension === key))
                                    .map((key) => (
                                        <Option key={key} value={key}>
                                            {key}
                                        </Option>
                                    ))}
                            </Select>
                        </div>
                    )}

                    <Tooltip title="Show/Hide Filters">
                        <Button
                            type="link"
                            icon={
                                dropdownVisibility.filters ? (
                                    <EyeInvisibleOutlined />
                                ) : (
                                    <EyeOutlined />
                                )
                            }
                            onClick={() => toggleDropdownVisibility("filters")}
                        />
                    </Tooltip>

                    {dropdownVisibility.keyword && (
                        <Input
                            placeholder="Search by keyword"
                            value={keyword}
                            onChange={(e) => setKeyword(e.target.value)}
                            style={{ width: "100%", marginBottom: 16 }}
                        />
                    )}

                    <Tooltip title="Show/Hide Keyword Search">
                        <Button
                            type="link"
                            icon={
                                dropdownVisibility.keyword ? (
                                    <EyeInvisibleOutlined />
                                ) : (
                                    <EyeOutlined />
                                )
                            }
                            onClick={() => toggleDropdownVisibility("keyword")}
                        />
                    </Tooltip>

                    {dropdownVisibility.topN && (
                        <InputNumber
                            placeholder="Top N"
                            min={1}
                            value={topN || undefined}
                            onChange={(value) => setTopN(value)}
                            style={{ width: "100%", marginBottom: 16 }}
                        />
                    )}

                    <Tooltip title="Show/Hide Top N">
                        <Button
                            type="link"
                            icon={
                                dropdownVisibility.topN ? (
                                    <EyeInvisibleOutlined />
                                ) : (
                                    <EyeOutlined />
                                )
                            }
                            onClick={() => toggleDropdownVisibility("topN")}
                        />
                    </Tooltip>

                    {dropdownVisibility.groupBy && (
                        <Row gutter={[16, 16]} style={{ marginBottom: 16 }}>
                            <Col span={12}>
                                <Select
                                    style={{ width: "100%" }}
                                    placeholder="Group By"
                                    value={angleField || undefined}
                                    onChange={(value) => setAngleField(value)}
                                    allowClear
                                >
                                    {keys.map((key) => (
                                        <Option key={key} value={key}>
                                            {key}
                                        </Option>
                                    ))}
                                </Select>
                            </Col>
                            <Col span={12}>
                                <Select
                                    style={{ width: "100%" }}
                                    placeholder="Aggregation"
                                    value={aggregation}
                                    onChange={(value) => setAggregation(value)}
                                >
                                    <Option value="sum">Sum</Option>
                                    <Option value="average">Average</Option>
                                    <Option value="count">Count</Option>
                                </Select>
                            </Col>
                        </Row>
                    )}

                    <Tooltip title="Show/Hide Group By">
                        <Button
                            type="link"
                            icon={
                                dropdownVisibility.groupBy ? (
                                    <EyeInvisibleOutlined />
                                ) : (
                                    <EyeOutlined />
                                )
                            }
                            onClick={() => toggleDropdownVisibility("groupBy")}
                        />
                    </Tooltip>

                    <Pie {...config} />
                </div>
            </ResizableBox>
        </div>
    );
};

export default ResizableDonutChart;
