import React, { useState, useRef, useEffect } from 'react';
import { Semantic, Index } from '../configuration/Interface';
import { Card, CardMedia, CardContent, Typography, Box, useTheme, Paper, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button, IconButton, Modal, Tooltip, Divider } from '@mui/material';
import VideoBoxInteract from '../VideoBoxInteract';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import ScoreIcon from '@mui/icons-material/EmojiEvents';
import PageIcon from '@mui/icons-material/InsertDriveFile';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { handleTimes } from '../../searchComponents/UtilityFunctions';
import { deleteSemanticSearchFromPlaylistSlice } from '../../../features/playlistSlice';
import { useDispatch, useSelector } from 'react-redux';
import { RootState, AppDispatch } from '../../../store';
import DeleteIcon from '@mui/icons-material/Delete';
import MovieIcon from '@mui/icons-material/Movie';
import { saveMovieToPlaylistSlice } from '../../../features/playlistSlice';
import { getImagesFiles } from '../../../api/serverApis/filesApi';
import pdfImage from '../../../images/pdfImage.jpg';
import videoImage from '../../../images/videoImage.jpg';
interface SemanticProps {
  data: Semantic | Index;
  isOpen: boolean;
  handlePlaylistSelectClick?: any;
  //solverSearchData: any;
  searchData: any;
  playlistId: string;
}

const VideoSemanticPlaylist: React.FC<SemanticProps> = ({ data, isOpen, handlePlaylistSelectClick, searchData, playlistId }) => {
  const theme = useTheme();
  const [openVideoInteract, setOpenVideoInteract] = useState(false);
  const [hover, setHover] = useState(false);
  const [showMovie, setShowMovie] = useState(false);
  const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false);
  const [imageUrl, setImageUrl] = useState<string | null>(null);

  const videoCardRef = useRef<HTMLDivElement>(null);
  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    if (data.movieLink.indexOf('.pdf') !== -1) {
      const fetchImage = async () => {
        if (!data.pageImage) return;
        try {
          const response = await getImagesFiles(data.pageImage);
          const imageBlob = new Blob([response.data], { type: 'image/jpeg' }); // Adjust the MIME type as necessary
          const imageUrl = URL.createObjectURL(imageBlob);
          setImageUrl(imageUrl);
        } catch (error) {
          console.error('Error fetching image:', error);
        }
      };
      fetchImage();
    }
  }, []);

  const handleImageClick = () => {
    setShowMovie(true);
  };

  const handleCloseVideoInteract = () => {
    setOpenVideoInteract(false);
  };
  const handleTimesLocally = (duration?: boolean) => {
    if (!data.pageNumber || data.pageNumber === '-1') {
      const { startTime, endTime, frameTime } = data;
      const sTime = startTime ? startTime.toString() : '';
      const eTime = endTime ? endTime.toString() : '';
      const fTime = frameTime ? frameTime.toString() : '';
      return handleTimes(sTime, eTime, fTime, duration);
    }
    if (data.pageNumber || data.pageNumber !== '-1') {
      return `Page: ${data.pageNumber}`;
    }
  };

  const getIconForFileType = () => {
    const isPDF = data.movieLink.indexOf('.pdf') != -1;
    return isPDF ? <PictureAsPdfIcon fontSize="large" /> : <PlayCircleOutlineIcon fontSize="large" />;
  };
  const handleMovieDelete = () => {
    // Close confirmation dialog first
    setDeleteConfirmOpen(false);
    // Dispatch your delete action here
    dispatch(deleteSemanticSearchFromPlaylistSlice({ courseId: data.courseId, playlistId: playlistId, _id: data._id ? data._id : '' }));
  };
  const borderColor = theme.palette.mode === 'dark' ? 'white' : 'black';
  const isHebrew = (text: string) => /[\u0590-\u05FF\uFB1D-\uFB4F\uFB50-\uFDFF]/.test(text);

  const isPdf = () => {
    return data.movieLink.indexOf('.pdf') !== -1;
  };

  const handleToggleMovie = () => {
    setShowMovie(!showMovie);
  };

  return (
    <Box>
      <Card
        ref={videoCardRef}
        sx={{
          display: isOpen ? 'none' : 'block',
          maxWidth: '100%',
          mb: 2,
          cursor: 'pointer',
          backgroundColor: theme.palette.background.paper,
          margin: '1rem',
          borderRadius: '1rem',
          border: '1px solid',
          borderColor: borderColor,
        }}
      >
        <CardContent sx={{ paddingBottom: '1rem' }}>
          <Typography variant="body1" sx={{ fontSize: '1rem', fontWeight: 'bold', direction: isHebrew(data.summaryText) ? 'rtl' : 'ltr' }}>
            {data.summaryText}
          </Typography>
        </CardContent>
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} onClick={handleImageClick}>
          <CardMedia
            component="img"
            sx={{
              width: isPdf() ? '100%' : '50%',
              marginLeft: isPdf() ? '0' : '0.5%',
              height: isPdf() ? 'auto' : '150px',
              objectFit: 'cover',
              cursor: 'pointer',
              borderBottomLeftRadius: isPdf() ? '1rem' : 'none',
              borderBottomRightRadius: isPdf() ? '1rem' : 'none',
            }}
            image={isPdf() ? imageUrl || pdfImage : data.pageImage || videoImage}
            alt="PDF IMAGE"
            onClick={handleImageClick}
          />
          {!isPdf() && (
            <Tooltip title="Play Video">
              <Box
                sx={{
                  position: 'relative',
                  top: '30px',
                  right: '110px',
                  transform: 'translate(-50%, -50%)',
                  color: 'white',
                  backgroundColor: 'rgba(0, 0, 0,0.8)', // Optional dark background for better visibility
                  borderRadius: '50%',
                  padding: '8px',
                  paddingBottom: '3px',
                  cursor: 'pointer',
                }}
                onClick={handleToggleMovie}
              >
                <PlayCircleOutlineIcon sx={{ fontSize: '50px', color: 'white' }} />
              </Box>
            </Tooltip>
          )}
        </Box>
        <Box sx={{ padding: '0.5rem', textAlign: 'center' }}>
          <Tooltip title={`Score: ${data.score}`}>
            <Typography variant="body2" sx={{ display: 'inline-flex', alignItems: 'center' }}>
              <ScoreIcon sx={{ fontSize: '20px', color: theme.palette.success.main, mr: 1 }} />
              {data.score}
            </Typography>
          </Tooltip>

          {isPdf() ? (
            <Tooltip title={`Page: ${data.pageNumber}`}>
              <Typography variant="body2" sx={{ display: 'inline-flex', alignItems: 'center', ml: 2 }}>
                <PageIcon sx={{ fontSize: '20px', color: theme.palette.info.main, mr: 1 }} />
                {data.pageNumber}
              </Typography>
            </Tooltip>
          ) : (
            <>
              <Tooltip title={`${handleTimesLocally(true)}`}>
                <Typography variant="body2" sx={{ display: 'inline-flex', alignItems: 'center', ml: 2 }}>
                  <AccessTimeIcon sx={{ fontSize: '20px', color: theme.palette.info.main, mr: 1 }} />
                  {handleTimesLocally(true)}
                </Typography>
              </Tooltip>
            </>
          )}
        </Box>

        <Divider />

        <Box sx={{ display: 'flex', justifyContent: 'space-between', padding: '0.5rem', alignItems: 'center' }}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Tooltip title="Delete the movie">
              <IconButton
                sx={{
                  color: theme.palette.info.main,
                }}
                onClick={() => setDeleteConfirmOpen(true)}
              >
                <DeleteIcon sx={{ fontSize: '25px' }} />
              </IconButton>
            </Tooltip>
            {isPdf() ?
              <Tooltip title="View Pdf">
                <IconButton
                  sx={{
                    color: theme.palette.info.main,
                  }}
                  onClick={handleToggleMovie}
                >
                  <PictureAsPdfIcon sx={{ fontSize: '30px' }} />
                </IconButton>
              </Tooltip> :
              <Tooltip title="Play Video">
                <IconButton
                  sx={{
                    color: theme.palette.info.main,
                  }}
                  onClick={handleToggleMovie}
                >
                  <PlayCircleOutlineIcon sx={{ fontSize: '30px' }} />
                </IconButton>
              </Tooltip>
            }
          </Box>
        </Box>

        <Box sx={{ display: 'flex', justifyContent: 'flex-start', padding: '0.5rem', alignItems: 'center' }}>
          <MovieIcon sx={{ fontSize: '20px', color: theme.palette.secondary.main, mr: 1 }} />
          <Typography variant="subtitle2" sx={{ fontSize: '1rem' }}>
            Title: {data.title}
          </Typography>
        </Box>

        <Dialog
          open={deleteConfirmOpen}
          onClose={() => setDeleteConfirmOpen(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"Confirm Deletion"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Are you sure you want to delete this movie?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setDeleteConfirmOpen(false)}>Cancel</Button>
            <Button onClick={handleMovieDelete} autoFocus>
              Delete
            </Button>
          </DialogActions>
        </Dialog>
      </Card>

      {showMovie && (
        <Box sx={{ position: 'fixed', top: 0, left: 0, zIndex: 9000 }}>
          <VideoBoxInteract
            index={data}
            onClose={handleToggleMovie}
            yBox={0}
            xBox={100}
            widthBox={isPdf() ? 800 : 700}  // Wider for PDF
            heightBox={isPdf() ? 700 : 650}  // Higher for PDF
            zIndex={9009}
          />
        </Box>
      )}
    </Box>
  );
};

export default VideoSemanticPlaylist;