import React, { useState } from 'react';
import { Box, Typography, FormControl, InputLabel, Select, MenuItem, SelectChangeEvent } from '@mui/material';
import MonitoringTocWatch from '../components/monitoring/MonitoringTocWatch';
import MonitoringReferanceWatch from '../components/monitoring/MonitoringReferanceWatch';
import MainDashboard from '../components/monitoring/MainDashboard';

import Header from '../components/combinationSearchComponents/Header';

const MonitoringPage = () => {
  const [selectedComponent, setSelectedComponent] = useState<string>('toc');
  const [themeMode, setThemeMode] = useState<'light' | 'dark'>('light');

  const handleComponentChange = (event: SelectChangeEvent<string>) => {
    setSelectedComponent(event.target.value as string);
  };
  const toggleTheme = () => {
    setThemeMode(prevMode => prevMode === 'light' ? 'dark' : 'light');
  };

  return (
    <Box>
      <Box sx={{ position: 'fixed', top: 0, left: 0, width: '100%', zIndex: 1200 }}>
        <Header header={"Monitoring"} themeMode={themeMode} toggleTheme={toggleTheme} title={""} />
      </Box>
      <Box mt={8}>
        <Typography variant="h4" gutterBottom>Monitoring Dashboard</Typography>

        <FormControl fullWidth margin="normal">
          <InputLabel>Select Monitoring View</InputLabel>
          <Select
            value={selectedComponent}
            onChange={handleComponentChange}
          >
            <MenuItem value="toc">TOC Watch Monitoring</MenuItem>
            <MenuItem value="reference">Reference Watch Monitoring</MenuItem>
            <MenuItem value="dashboard">Dashboard </MenuItem>
          </Select>
        </FormControl>

        <Box mt={4}>
          {selectedComponent === 'toc' && <MonitoringTocWatch />}
          {selectedComponent === 'reference' && <MonitoringReferanceWatch />}
          {selectedComponent === 'dashboard' && <MainDashboard />}
        </Box>
      </Box>
    </Box>
  );
};

export default MonitoringPage;
