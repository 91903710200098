import React, { useState, useEffect, useRef } from 'react';
import ReactQuill, { Quill } from 'react-quill';
import { Rnd } from 'react-rnd';
import { Box, Button, Menu, MenuItem, TextField, IconButton, Tooltip } from '@mui/material';
import { UploadFile as UploadIcon, Edit as EditIcon, CloudDownload as DownloadIcon, OpenInFull, CloseFullscreen, CopyAll as CopyAllIcon } from '@mui/icons-material';
import CloseIcon from '@mui/icons-material/Close';
import 'react-quill/dist/quill.snow.css';
import 'quill-table-ui/dist/index.css';
import ReactDOM from 'react-dom';
// Add custom modules for image resizing
import ImageResize from 'quill-image-resize-module-react';
import QuillTable from 'quill-table-ui';
import { jsPDF } from 'jspdf';
import { saveAs } from 'file-saver';
import html2canvas from 'html2canvas';
import { Document, Packer, Paragraph, ImageRun } from 'docx';
import mammoth from 'mammoth';


// Register Quill modules
const Parchment = Quill.import('parchment');
if (Parchment) {
    Quill.register('modules/imageResize', ImageResize);
    Quill.register('modules/table', QuillTable);
}
const RichEditor = () => {
    const [value, setValue] = useState('');
    const [isFloating, setIsFloating] = useState(false);
    const [position, setPosition] = useState({ x: -100, y: 100 });
    const [size, setSize] = useState({ width: 600, height: 400 });
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const quillRef = useRef<ReactQuill | null>(null);
    const [filename, setFilename] = useState('editor-content');
    useEffect(() => {
        const handleEscapeKey = (event: KeyboardEvent) => {
            if (event.key === 'Escape') {
                setIsFloating(false);
            }
        };

        document.addEventListener('keydown', handleEscapeKey);

        return () => {
            document.removeEventListener('keydown', handleEscapeKey);
        };
    }, []);
    const handleDownloadMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };


    const handleDownloadMenuClose = () => {
        setAnchorEl(null);
    };

    const downloadAsPDF = async () => {
        try {
            if (!quillRef.current) {
                console.error('Quill instance not found.');
                return;
            }

            const quillInstance = quillRef.current.getEditor();
            const contentHTML = quillInstance.root.innerHTML; // Get the full HTML content of the editor

            const pdf = new jsPDF({
                orientation: 'portrait',
                unit: 'mm',
                format: 'a4',
            });

            const pageWidth = pdf.internal.pageSize.getWidth() - 20; // Margins
            const pageHeight = pdf.internal.pageSize.getHeight() - 20;
            const lineHeight = 10;
            const marginLeft = 10;
            const marginTop = 10;

            const tempContainer = document.createElement('div');
            tempContainer.innerHTML = contentHTML;
            document.body.appendChild(tempContainer); // Add temporary container to DOM

            let currentY = marginTop;

            tempContainer.querySelectorAll('*').forEach((element: any) => {
                const tagName = element.tagName.toLowerCase();

                if (tagName === 'p' || tagName === 'div' || tagName === 'span') {
                    const text = element.textContent || '';
                    const words = text.split(' ');

                    let line = '';
                    words.forEach((word: any) => {
                        const testLine = line + word + ' ';
                        const textWidth = pdf.getTextWidth(testLine);

                        if (textWidth > pageWidth) {
                            // Add current line to PDF and move to the next line
                            pdf.text(line, marginLeft, currentY);
                            line = word + ' ';
                            currentY += lineHeight;

                            // If page height is exceeded, add a new page
                            if (currentY > pageHeight) {
                                pdf.addPage();
                                currentY = marginTop;
                            }
                        } else {
                            line = testLine;
                        }
                    });

                    // Add the last line
                    pdf.text(line, marginLeft, currentY);
                    currentY += lineHeight;

                    if (currentY > pageHeight) {
                        pdf.addPage();
                        currentY = marginTop;
                    }
                } else if (tagName === 'img') {
                    const imgElement = element as HTMLImageElement;

                    const imgWidth = pageWidth * 0.5; // Scale image width to 50% of page width
                    const imgHeight = (imgElement.naturalHeight / imgElement.naturalWidth) * imgWidth; // Maintain aspect ratio

                    const imgData = imgElement.getAttribute('src');
                    if (imgData) {
                        if (currentY + imgHeight > pageHeight) {
                            pdf.addPage();
                            currentY = marginTop;
                        }
                        pdf.addImage(imgData, 'JPEG', marginLeft, currentY, imgWidth, imgHeight);
                        currentY += imgHeight + lineHeight;

                        if (currentY > pageHeight) {
                            pdf.addPage();
                            currentY = marginTop;
                        }
                    }
                }
            });

            tempContainer.remove(); // Clean up temporary container
            pdf.save(`${filename}.pdf`);
        } catch (error) {
            console.error('Error generating PDF:', error);
        }
    };



    const downloadAsHtml = () => {
        const blob = new Blob([value], { type: 'text/html;charset=utf-8' });
        saveAs(blob, `${filename}.html`);
        handleDownloadMenuClose();
    };
    const downloadAsText = () => {
        const blob = new Blob([value], { type: 'text/plain;charset=utf-8' }); // Correct MIME type
        saveAs(blob, `${filename}.txt`);
        handleDownloadMenuClose();
    };
    const handleFileUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];
        if (!file) return;

        console.log('File type:', file.type); // Debugging log

        const reader = new FileReader();

        reader.onload = async (e) => {
            const content = e.target?.result;

            if (file.type === 'text/plain') {
                // Handle .txt file
                setValue(content as string);
            } else if (file.type === 'text/html') {
                // Handle .html file
                setValue(content as string);
            } else if (file.type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') {
                // Handle .docx file using mammoth
                try {
                    const arrayBuffer = e.target?.result as ArrayBuffer;
                    const { value: text } = await mammoth.extractRawText({ arrayBuffer });
                    setValue(text);
                } catch (error) {
                    console.error('Error parsing .docx file:', error);
                    alert('Failed to upload .docx file.');
                }
            } else {
                alert('Unsupported file type');
            }
        };

        if (file.type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') {
            reader.readAsArrayBuffer(file);
        } else {
            reader.readAsText(file);
        }
    };
    // const downloadAsWord = async () => {
    //     try {
    //         if (!quillRef.current) {
    //             console.error('Quill instance not found.');
    //             return;
    //         }

    //         const quillInstance = quillRef.current.getEditor();
    //         const delta = quillInstance.getContents(); // Get the Quill delta

    //         // Define allowed alignments
    //         const allowedAlignments = [
    //             "start",
    //             "center",
    //             "end",
    //             "both",
    //             "mediumKashida",
    //             "distribute",
    //             "numTab",
    //             "highKashida",
    //             "lowKashida",
    //             "thaiDistribute",
    //             "left",
    //             "right",
    //         ] as const;

    //         // Convert Quill Delta to an array of Paragraphs
    //         const paragraphs = delta.ops
    //             .filter((op) => op.insert && typeof op.insert === 'string')
    //             .map((op) => {
    //                 const alignment = allowedAlignments.includes(op.attributes?.align as any)
    //                     ? (op.attributes?.align as typeof allowedAlignments[number])
    //                     : undefined;

    //                 return new Paragraph({
    //                     text: (op.insert as string).trim(),
    //                     style: op.attributes?.bold ? 'Bold' : undefined,
    //                     alignment,
    //                 });
    //             });

    //         // Create a new Word document
    //         const doc = new Document({
    //             sections: [
    //                 {
    //                     children: paragraphs,
    //                 },
    //             ],
    //         });

    //         // Generate the Word document as a Blob
    //         const blob = await Packer.toBlob(doc);

    //         // Use FileSaver.js to download the file
    //         saveAs(blob, `${filename}.docx`);
    //     } catch (error) {
    //         console.error('Error creating Word document:', error);
    //     }
    // };

    const downloadAsWord = async () => {
        try {
            if (!quillRef.current) {
                console.error('Quill instance not found.');
                return;
            }

            const quillInstance = quillRef.current.getEditor();
            const contentHTML = quillInstance.root.innerHTML;

            // Create a temporary container to parse the HTML content
            const tempContainer = document.createElement('div');
            tempContainer.innerHTML = contentHTML;

            const paragraphs = [];

            for (const element of tempContainer.childNodes) {
                if (element.nodeType === Node.TEXT_NODE || element.nodeName === "P") {
                    // Handle text nodes or <p> elements
                    paragraphs.push(new Paragraph({ text: element.textContent || "" }));
                } else if (element.nodeName === "IMG") {
                    // Handle <img> elements
                    const imgElement = element as HTMLImageElement;
                    const src = imgElement.getAttribute('src');

                    if (src && src.startsWith("data:image/")) {
                        const base64Data = src.split(",")[1];
                        const imageBuffer = Uint8Array.from(atob(base64Data), (char) => char.charCodeAt(0));
                        const mimeType = src.split(";")[0].split(":")[1];
                        const imageType: any = mimeType === "image/jpeg" ? "jpg" : mimeType.split("/")[1];
                        const imageRun = new ImageRun({
                            data: imageBuffer,
                            transformation: {
                                width: imgElement.width || 100, // Provide default width
                                height: imgElement.height || 100, // Provide default height
                            },
                            type: imageType, // Dynamically set the image type
                        });
                        paragraphs.push(new Paragraph({ children: [imageRun] }));
                    }
                }
            }

            // Clean up the temporary container
            tempContainer.remove();

            // Create a Word document
            const doc = new Document({
                sections: [
                    {
                        children: paragraphs,
                    },
                ],
            });

            // Generate the Word document as a Blob
            const blob = await Packer.toBlob(doc);
            saveAs(blob, `${filename}.docx`);
        } catch (error) {
            console.error('Error creating Word document:', error);
        }
    };
    const copyToClipboard = () => {
        if (!quillRef.current) {
            console.error('Quill instance not found.');
            return;
        }

        const quillInstance = quillRef.current.getEditor();
        const delta = quillInstance.getContents(); // Get the Quill Delta (structured format)

        // Create an RTF string with text and images
        let rtfContent = '';
        delta.ops.forEach((op: any) => {
            if (typeof op.insert === 'string') {
                // Add formatted text
                let text = op.insert;
                if (op.attributes) {
                    if (op.attributes.bold) {
                        text = `\\b ${text}\\b0`; // RTF bold formatting
                    }
                    if (op.attributes.italic) {
                        text = `\\i ${text}\\i0`; // RTF italic formatting
                    }
                    if (op.attributes.underline) {
                        text = `\\ul ${text}\\ulnone`; // RTF underline formatting
                    }
                }
                rtfContent += text;
            } else if (op.insert.image) {
                // Add image (base64 data URI)
                const imageSrc = op.insert.image;
                if (imageSrc.startsWith('data:image/')) {
                    const base64Data = imageSrc.split(',')[1];
                    const binary = atob(base64Data);
                    let hex = '';
                    for (let i = 0; i < binary.length; i++) {
                        const byte = binary.charCodeAt(i).toString(16);
                        hex += (byte.length === 1 ? '0' : '') + byte;
                    }
                    rtfContent += `{\\pict\\pngblip\\picw100\\pich100\\hex ${hex}}`; // Embed image in RTF
                }
            }
        });

        // Copy RTF content to the clipboard
        const blob = new Blob([rtfContent], { type: 'text/rtf' });
        const clipboardItem = new ClipboardItem({ 'text/rtf': blob });

        navigator.clipboard.write([clipboardItem])
            .then(() => alert('Content copied to clipboard!'))
            .catch((error) => console.error('Error copying to clipboard:', error));
    };
    const quillModules = {
        toolbar: [
            [{ header: [1, 2, 3, 4, 5, 6, false] }],
            [{ font: [] }],
            [{ size: ['small', false, 'large', 'huge'] }],
            ['bold', 'italic', 'underline', 'strike'],
            [{ color: [] }, { background: [] }],
            [{ script: 'sub' }, { script: 'super' }],
            [{ list: 'ordered' }, { list: 'bullet' }],
            [{ indent: '-1' }, { indent: '+1' }],
            [{ align: [] }],
            ['blockquote', 'code-block'],
            [{ direction: 'rtl' }, { direction: 'ltr' }],
            ['link', 'image', 'video'],
            [{ table: 'insert-table' }],
            ['clean'],
            ['undo', 'redo'],
        ],
        imageResize: {
            modules: ['Resize', 'DisplaySize', 'Toolbar'],
        },
        table: true,
    };

    const quillFormats = [
        'header',
        'font',
        'size',
        'bold',
        'italic',
        'underline',
        'strike',
        'color',
        'background',
        'script',
        'list',
        'bullet',
        'indent',
        'align',
        'blockquote',
        'code-block',
        'direction',
        'link',
        'image',
        'video',
        'table',
    ];

    const renderFloatingEditor = () => (
        <Rnd
            style={{
                background: 'white',
                boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.3)',
                zIndex: 21474839,
                position: 'fixed',
                borderRadius: '8px',
                overflow: 'hidden',
            }}
            position={{ x: position.x, y: position.y }}
            size={{ width: size.width, height: size.height }}
            onDragStop={(e, d) => {
                setPosition({ x: d.x, y: d.y });
            }}
            onResizeStop={(e, direction, ref, delta, position) => {
                setSize({
                    width: parseInt(ref.style.width, 10),
                    height: parseInt(ref.style.height, 10),
                });
                setPosition(position);
            }}
        >
            <Box sx={{ position: 'relative', height: '100%' }}>
                <IconButton
                    sx={{
                        position: 'absolute',
                        top: 8,
                        right: 8,
                        zIndex: 100,
                        backgroundColor: 'white',
                    }}
                    onClick={() => setIsFloating(false)}
                >
                    <CloseIcon />
                </IconButton>
                <ReactQuill

                    theme="snow"
                    value={value}
                    onChange={setValue}
                    modules={quillModules}
                    formats={quillFormats}
                    style={{
                        height: '80%',
                        borderRadius: '8px',
                        border: 'none',
                    }}
                />
            </Box>
        </Rnd>
    );

    return (
        <Box sx={{ p: 2, marginTop: '5%', display: 'flex', flexDirection: 'column', gap: 2 }}>
            <Box sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', gap: '8px' }}>
                <Tooltip title={isFloating ? 'Exit Float Mode' : 'Float Mode'}>
                    <IconButton color="primary" onClick={() => setIsFloating(!isFloating)}>
                        {isFloating ? <CloseFullscreen /> : <OpenInFull />}
                    </IconButton>
                </Tooltip>
                <input
                    type="file"
                    accept=".txt,.html,.docx"
                    onChange={handleFileUpload}
                    style={{ display: 'none' }}
                    id="file-upload"
                />
                <label htmlFor="file-upload">
                    <Tooltip title="Upload File">
                        <IconButton component="span" color="primary">
                            <UploadIcon />
                        </IconButton>
                    </Tooltip>
                </label>
                <Tooltip title="Change File Name">
                    <IconButton color="secondary" onClick={() => setFilename(prompt('Enter new file name:', filename) || filename)}>
                        <EditIcon />
                    </IconButton>
                </Tooltip>
                <Tooltip title="Copy All Content">
                    <IconButton color="secondary" onClick={copyToClipboard}>
                        <CopyAllIcon />
                    </IconButton>
                </Tooltip>

                <Tooltip title="Download Options">
                    <IconButton color="secondary" onClick={handleDownloadMenuOpen}>
                        <DownloadIcon />
                    </IconButton>
                </Tooltip>
                <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleDownloadMenuClose}>
                    <MenuItem onClick={downloadAsPDF}>Download as PDF</MenuItem>
                    <MenuItem onClick={downloadAsText}>Download as Text</MenuItem>
                    <MenuItem onClick={downloadAsWord}>Download as Word</MenuItem>
                    <MenuItem onClick={downloadAsHtml}>Download as HTML</MenuItem>
                </Menu>
            </Box>
            {isFloating
                ? ReactDOM.createPortal(
                    renderFloatingEditor(),
                    document.getElementById('portal-root') || document.body
                )
                : (
                    <Box
                        sx={{
                            width: '94%',
                            height: '400px',
                            background: 'white',
                            borderRadius: '8px',
                        }}
                    >
                        <ReactQuill
                            ref={quillRef}
                            theme="snow"
                            value={value}
                            onChange={setValue}
                            modules={quillModules}
                            formats={quillFormats}
                            style={{
                                height: '100%',
                            }}
                        />
                    </Box>
                )
            }
        </Box >
    );
};

export default RichEditor;
