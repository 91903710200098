

import cubejs from '@cubejs-client/core';

const CUBE_API_URL = 'http://localhost:4000/cubejs-api/v1'; // Adjust to your Cube.js backend URL
const CUBE_API_TOKEN = '6f780e2bb1989c658950b36642576161762a7d9f12cd570193dad01a46d998047e8c690774b54d48a4d2588234c6543b91d9b760c99b2f2d64160e8b57955c80'; // Replace with your Cube API token

const cubejsApi = cubejs(CUBE_API_TOKEN, {
    apiUrl: CUBE_API_URL,
});

export default cubejsApi;