import React from "react";
import { Typography, Box } from "@mui/material";

interface HierarchyProps {
    flowHistory: any[];
    setFlowHistory: React.Dispatch<React.SetStateAction<any[]>>; // Explicitly type as a state setter
    currentFlow: any;
    resetToMainFlow: () => void; // Add a function to reset to the main flow
}

const Hierarchy = ({ flowHistory, setFlowHistory, currentFlow, resetToMainFlow }: HierarchyProps) => {
    return (
        <Box sx={{ display: "flex", flexDirection: "row-reverse", alignItems: "center", gap: 1 }}>
            {/* Current Flow */}
            <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                {currentFlow.header}
            </Typography>

            {/* Separator */}
            <Typography variant="body1" sx={{ mx: 1 }}>
                →
            </Typography>

            {/* History Items */}
            {flowHistory
                .slice()
                .reverse()
                .map((flow, index) => (
                    <React.Fragment key={index}>
                        <Typography
                            variant="body1"
                            sx={{
                                cursor: "pointer",
                                color: "blue",
                                textDecoration: "underline",
                            }}
                            onClick={() => {
                                const historyIndex = flowHistory.length - 1 - index;
                                setFlowHistory((prev: any[]) => prev.slice(0, historyIndex));
                            }}
                        >
                            {flow.header}
                        </Typography>
                        <Typography variant="body1" sx={{ mx: 1 }}>
                            →
                        </Typography>
                    </React.Fragment>
                ))}

            {/* Main Flow Item */}
            <Typography
                variant="body1"
                sx={{
                    cursor: "pointer",
                    color: "blue",
                    textDecoration: "underline",
                }}
                onClick={resetToMainFlow}
            >
                Main
            </Typography>
        </Box>
    );
};

export default Hierarchy;
