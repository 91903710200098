import React from "react";
import { Select } from "antd";

const { Option } = Select;

interface CubeSelectorProps {
    meta: any[];
    onChange: (cubeNames: string[]) => void;
}

const CubeSelector: React.FC<CubeSelectorProps> = ({ meta, onChange }) => {
    return (
        <div>
            <h4>Select Tables</h4>
            <Select
                mode="multiple"
                style={{ width: "100%" }}
                placeholder="Select one or more tables"
                onChange={(values) => onChange(values as string[])} // Cast to string[]
            >
                {meta.map((cube) => (
                    <Option key={cube.name} value={cube.name}>
                        {cube.title || cube.name}
                    </Option>
                ))}
            </Select>
        </div>
    );
};

export default CubeSelector;
