import React, { useState } from "react";
import { Input, Tooltip } from "antd";
import { Sankey } from "@ant-design/charts";
import { ResizableBox } from "react-resizable";
import { EditOutlined } from "@ant-design/icons";
import "react-resizable/css/styles.css";

interface SankeyChartProps {
    data: any;
    sourceField: string;
    targetField: string;
    valueField: string;
}

const ResizableSankeyChart: React.FC<SankeyChartProps> = ({ data, sourceField, targetField, valueField }) => {
    const [chartSize, setChartSize] = useState({ width: 600, height: 400 }); // Initial size
    const [title, setTitle] = useState("Sankey Diagram"); // Editable title
    const [editingTitle, setEditingTitle] = useState(false); // Whether the title is being edited
    const [hovered, setHovered] = useState(false); // Whether the graph is hovered

    const config = {
        data,
        sourceField,
        targetField,
        valueField,
        appendPadding: [20, 20, 20, 20], // Add padding around the chart
        autoFit: false, // Prevent auto-fitting to parent
        width: chartSize.width - 20, // Account for padding in width
        height: chartSize.height - 60, // Account for padding in height
        nodeStyle: {
            stroke: "#bbb",
        },
        edgeStyle: {
            strokeOpacity: 0.6,
        },
    };

    const handleResize = (event: any, { size }: { size: { width: number; height: number } }) => {
        setChartSize(size); // Dynamically update chart size on resize
    };

    return (
        <div
            onMouseEnter={() => setHovered(true)}
            onMouseLeave={() => setHovered(false)}
            style={{
                position: "relative",
                marginBottom: 20,
                padding: "10px",
                borderRadius: "8px",
                backgroundColor: "#fff",
                transition: "box-shadow 0.3s",
                boxShadow: hovered ? "0 4px 8px rgba(0, 0, 0, 0.2)" : "none",
            }}
        >
            <ResizableBox
                width={chartSize.width}
                height={chartSize.height}
                minConstraints={[300, 200]} // Minimum width and height
                maxConstraints={[800, 800]} // Maximum width and height
                resizeHandles={hovered ? ["se", "e", "s"] : []} // Show handles only when hovered
                onResizeStop={handleResize} // Update size when resizing stops
                style={{
                    width: "100%",
                    height: "100%",
                }}
            >
                <div>
                    <div style={{ display: "flex", alignItems: "center", marginBottom: 10 }}>
                        {editingTitle ? (
                            <Input
                                value={title}
                                onChange={(e) => setTitle(e.target.value)} // Update title
                                onBlur={() => setEditingTitle(false)} // Stop editing when input loses focus
                                autoFocus
                                style={{
                                    fontSize: "16px",
                                    fontWeight: "bold",
                                    border: "none",
                                    padding: 0,
                                }}
                                placeholder="Enter chart title"
                            />
                        ) : (
                            <Tooltip title="Click to edit the chart title">
                                <div
                                    style={{
                                        fontSize: "16px",
                                        fontWeight: "bold",
                                        cursor: "pointer",
                                        display: "flex",
                                        alignItems: "center",
                                    }}
                                    onClick={() => setEditingTitle(true)} // Enable editing on click
                                >
                                    {title}
                                    <EditOutlined style={{ marginLeft: 8 }} />
                                </div>
                            </Tooltip>
                        )}
                    </div>
                    <Sankey {...config} />
                </div>
            </ResizableBox>
        </div>
    );
};

export default ResizableSankeyChart;
