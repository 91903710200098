import React, { useState } from "react";
import { Card, Table, Input, Dropdown, Menu, Button, Checkbox } from "antd";
import { SearchOutlined, SettingOutlined, DownOutlined } from "@ant-design/icons";



const EnhancedDataTable = ({ data, columns, rowKey }: { data: any[]; columns: any[]; rowKey: any }) => {
    const [filters, setFilters] = useState<{ [key: string]: string[] }>({});
    const [sortConfig, setSortConfig] = useState<{ key: string; order: "ascend" | "descend" | null }>({
        key: "",
        order: null,
    });
    const [globalSearch, setGlobalSearch] = useState("");
    const [visibleColumns, setVisibleColumns] = useState(columns.map((col) => col.key));
    const [groupBy, setGroupBy] = useState<string | null>(null);

    // Handle global search filtering
    const handleGlobalSearch = (value: string) => {
        setGlobalSearch(value);
    };

    // Apply global search and column-specific filters
    const filteredData = data.filter((record) => {
        const matchesGlobalSearch = globalSearch
            ? Object.values(record).some((val) =>
                String(val).toLowerCase().includes(globalSearch.toLowerCase())
            )
            : true;

        const matchesColumnFilters = Object.entries(filters).every(([key, values]) => {
            if (!values || !values.length) return true;
            return values.includes(record[key]);
        });

        return matchesGlobalSearch && matchesColumnFilters;
    });

    // Sort data based on the current sort configuration
    const sortedData = [...filteredData].sort((a, b) => {
        if (!sortConfig.key || !sortConfig.order) return 0;

        const valueA = a[sortConfig.key];
        const valueB = b[sortConfig.key];

        if (sortConfig.order === "ascend") {
            return typeof valueA === "number"
                ? valueA - valueB
                : String(valueA).localeCompare(String(valueB));
        }

        return typeof valueA === "number"
            ? valueB - valueA
            : String(valueB).localeCompare(String(valueA));
    });

    // Group data if groupBy is set
    const groupedData = groupBy
        ? Object.entries(
            sortedData.reduce<Record<string, any[]>>((acc, record) => {
                const key = record[groupBy] || "Ungrouped";
                if (!acc[key]) acc[key] = [];
                acc[key].push(record);
                return acc;
            }, {})
        ).map(([key, group]) => ({
            key,
            count: group.length,
            records: group,
        }))
        : sortedData;

    // Handle column visibility toggling
    const toggleColumnVisibility = (key: string) => {
        setVisibleColumns((prev) =>
            prev.includes(key) ? prev.filter((colKey) => colKey !== key) : [...prev, key]
        );
    };

    // Handle group by column
    const handleGroupBy = (columnKey: string | null) => {
        setGroupBy(columnKey);
    };

    // Generate menu for column visibility and grouping
    const columnMenu = (
        <Menu>
            <Menu.SubMenu title="Columns">
                {columns.map((col) => (
                    <Menu.Item key={col.key}>
                        <Checkbox
                            checked={visibleColumns.includes(col.key)}
                            onChange={() => toggleColumnVisibility(col.key)}
                        >
                            {col.title}
                        </Checkbox>
                    </Menu.Item>
                ))}
            </Menu.SubMenu>

            <Menu.SubMenu title="Group By">
                {columns.map((col) => (
                    <Menu.Item key={`group-${col.key}`} onClick={() => handleGroupBy(col.dataIndex)}>
                        {col.title}
                    </Menu.Item>
                ))}
                <Menu.Divider />
                <Menu.Item key="clear-group" onClick={() => handleGroupBy(null)}>
                    Clear Grouping
                </Menu.Item>
            </Menu.SubMenu>
        </Menu>
    );

    // Enhance columns with filters, sorting, and visibility
    const enhancedColumns = [
        {
            title: (
                <Dropdown overlay={columnMenu} trigger={["click"]}>
                    <Button icon={<SettingOutlined />} />
                </Dropdown>
            ),
            dataIndex: "actions",
            key: "actions",
            width: 50,
        },
        ...columns
            .filter((col) => visibleColumns.includes(col.key))
            .map((col) => ({
                ...col,
                sorter: true,
                sortOrder: sortConfig.key === col.dataIndex ? sortConfig.order : null,
                filters: Array.from(new Set(data.map((item) => item[col.dataIndex]))).map((value) => ({
                    text: value,
                    value,
                })),
                onFilter: (value: any, record: any) => record[col.dataIndex] === value,
            })),
    ];

    return (
        <Card title="Enhanced Data Table with Header Filters and Grouping">
            {/* Global Search Input */}
            <Input
                placeholder="Global Search"
                prefix={<SearchOutlined />}
                value={globalSearch}
                onChange={(e) => handleGlobalSearch(e.target.value)}
                style={{ marginBottom: 16 }}
            />

            {/* Data Table */}
            <Table
                dataSource={
                    groupBy
                        ? groupedData.map((group) => ({
                            ...group,
                            children: group.records,
                        }))
                        : sortedData
                }
                columns={groupBy ? [
                    { title: groupBy, dataIndex: "key", key: "key" },
                    { title: "Count", dataIndex: "count", key: "count" },
                    ...enhancedColumns
                ] : enhancedColumns}
                rowKey={(record) => record[rowKey] || record.key}
                pagination={{ pageSize: 10 }}
                onChange={(pagination, filters, sorter: any) => {
                    setSortConfig({
                        key: sorter.columnKey || "",
                        order: sorter.order,
                    });
                    setFilters(filters as { [key: string]: string[] });
                }}
                expandable={{
                    expandedRowRender: (group) =>
                        groupBy && group.records ? (
                            <Table
                                dataSource={group.records}
                                columns={enhancedColumns.filter((col) => col.dataIndex !== "actions")}
                                rowKey={(record) => record[rowKey]}
                                pagination={false}
                            />
                        ) : null,
                }}
                scroll={{ x: true }}
            />
        </Card>
    );
};

export default EnhancedDataTable;
