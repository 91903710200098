import React from "react";
import { Select } from "antd";

const { Option } = Select;

interface ChartVisibilitySelectorProps {
    chartVisibility: string[];
    setChartVisibility: (value: string[]) => void;
}

const ChartVisibilitySelector: React.FC<ChartVisibilitySelectorProps> = ({ chartVisibility, setChartVisibility }) => {
    const chartOptions = [
        { label: "Line Chart", value: "line" },
        { label: "Bar Chart", value: "bar" },
        { label: "Pie Chart", value: "pie" },
        { label: "Area Chart", value: "area" },
        { label: "Data Table", value: "table" },
        { label: "KPI Cards", value: "kpi" },
        // { label: "Geospatial Map", value: "geospatialMap" },
        { label: "Funnel Chart", value: "funnel" },
        { label: "Progress Chart", value: "progress" },
        { label: "Donut Chart", value: "donut" },
        { label: "Expandable Panel", value: "expandablePanel" },
        // { label: "Interactive Filters", value: "interactiveFilters" },
        { label: "Real-Time Updates", value: "realTimeUpdates" },
        { label: "Sankey Diagram", value: "sankey" },
        { label: "Sparkline", value: "sparkline" },
    ];

    return (
        <div>
            <h4>Select Charts/Table to Display</h4>
            <Select
                mode="multiple"
                placeholder="Select charts or table"
                style={{ width: "100%" }}
                value={chartVisibility}
                onChange={setChartVisibility}
                allowClear
            >
                {chartOptions.map((chart) => (
                    <Option key={chart.value} value={chart.value}>
                        {chart.label}
                    </Option>
                ))}
            </Select>
        </div>
    );
};

export default ChartVisibilitySelector;
