import { Node, Edge } from "reactflow";

// Define the node width and height
const nodeWidth = 200;
const nodeHeight = 50;

// Define a color palette for flows
const colorPalette = [
    "#FF7F50", "#FFD700", "#90EE90", "#87CEEB",
    "#BA55D3", "#FFA07A", "#FF6347", "#40E0D0",
    "#FFDAB9", "#98FB98",
];

// Define consistent styles for nodes
const mainFlowNodeStyles = {
    border: "3px solid black", // Solid black border and thicker
    borderRadius: "8px",
    padding: "10px",
    backgroundColor: "#ffffff",
    boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
    fontSize: "20px",
    textAlign: "center" as "center",
};

const mainFlowEdgeStyle = {
    stroke: "black", // Solid black color for edges
    strokeWidth: 3, // Thicker edge
};

export const parseMainFlow = (flow: string): { nodes: Node[]; edges: Edge[] } => {
    const lines = flow.split("\n").filter(Boolean);
    const nodes: Node[] = [];
    const edges: Edge[] = [];

    // Handle the first two nodes
    if (lines.length > 1) {
        const firstLineMatch = lines[1].match(/(\w+)\[(.+?)\] --> (\w+)\[(.+?)\]/);
        if (firstLineMatch) {
            const [_, nodeIdA, labelA, nodeIdB, labelB] = firstLineMatch;

            // Add node A
            nodes.push({
                id: nodeIdA,
                data: { label: labelA },
                position: { x: 0, y: 0 },
                style: mainFlowNodeStyles, // Apply main flow node style
            });

            // Add node B
            nodes.push({
                id: nodeIdB,
                data: { label: labelB },
                position: { x: 200, y: 0 }, // Adjust position as needed
                style: mainFlowNodeStyles, // Apply main flow node style
            });

            // Add edge A --> B
            edges.push({
                id: `${nodeIdA}-${nodeIdB}`,
                source: nodeIdA,
                target: nodeIdB,
                animated: true,
                style: mainFlowEdgeStyle, // Apply main flow edge style
            });
        }
    }

    // Process the remaining lines
    for (let i = 2; i < lines.length; i++) {
        const line = lines[i];

        const nodeMatch = line.match(/(\w+)\[(.+?)\]/);
        if (nodeMatch) {
            const [_, nodeId, label] = nodeMatch;

            nodes.push({
                id: nodeId,
                data: { label },
                position: { x: 0, y: 0 },
                style: mainFlowNodeStyles, // Apply main flow node style
            });
        }

        const edgeMatch = line.match(/(\w+) --> (\w+)/);
        if (edgeMatch) {
            const [_, source, target] = edgeMatch;

            edges.push({
                id: `${source}-${target}`,
                source,
                target,
                animated: true,
                style: mainFlowEdgeStyle, // Apply main flow edge style
            });
        }
    }

    return { nodes, edges };
};
