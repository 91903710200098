import React, { useEffect, useRef, useState } from 'react';
import { Box, CardContent, Typography, Stack, useTheme, Paper, Accordion, AccordionSummary, AccordionDetails, Tab, useMediaQuery } from '@mui/material';
import { styled, width } from '@mui/system';
import ReactPlayerComponent from '../videoPlayer/ReactPlayerComponent';
import PDFViewer from '../../generalComponents/PDFViewer';
import { RootState, AppDispatch } from '../../../store';
import { useSelector, useDispatch } from 'react-redux';
import { fetchBlob, selectUrlByBlob } from '../../../features/blobFilesSlice';
import LoadingDots from '../mainComponenets/LoadingDots';
import ReactStreamingComponent from '../videoPlayer/ReactStreamingComponent';
import VideoDownloader from '../videoPlayer/VideoDownloader';
import { Divider } from '@material-ui/core';
import CodeViewer from '../../generalComponents/CodeViewer';
interface MainVideoProps {
  videoUrl: string;
  videoData: any;
  onSearch: (searchTerm: string, time?: string, articleId?: string) => void;
  setIsLoading?: any;
  onTakeTest: (quizData: any) => void;
  movieList: any[];
  onVideoComplete: (movieId: string, subVideoId: string) => void;
}
const TriangleLink = styled('div')(({ theme }) => ({
  position: 'relative',
  padding: '10px 20px',
  textDecoration: 'none',
  color: theme.palette.primary.main,
  backgroundColor: theme.palette.background.default,
  cursor: 'pointer',
  '&:after': {
    content: '""',
    position: 'absolute',
    top: '50%',
    right: '-10px',
    transform: 'translateY(-50%)',
    borderLeft: '10px solid transparent',
    borderRight: '10px solid transparent',
    borderTop: '10px solid ' + theme.palette.background.default,
  },
  '&:last-child:after': {
    display: 'none',
  },
  '&.active': {
    fontWeight: 'bold',
    color: theme.palette.primary.main,
  },
  '&.watched': {
    backgroundColor: 'lightgreen',
  }
}));
const MainVideo: React.FC<MainVideoProps> = ({ videoUrl, videoData, onSearch, setIsLoading, movieList, onTakeTest, onVideoComplete }) => {
  const theme = useTheme();
  const dispatch = useDispatch<AppDispatch>();
  const [contentType, setContentType] = useState<string>('');
  const localUrl = useSelector((state: RootState) => selectUrlByBlob(state, videoUrl));
  const blobLoading = useSelector((state: RootState) => state.blobs.loading);
  const [videoBlobUrl, setVideoBlobUrl] = useState<string | null>(null);
  const [blobParts, setBlobParts] = useState<BlobPart[]>([]);
  const [isMp4, setIsMp4] = useState(false);

  useEffect(() => {
    setVideoBlobUrl(null); // Reset to ensure new video loads
    setBlobParts([]);
  }, [videoUrl, localUrl, videoData]);
  useEffect(() => {
    return () => {
      if (videoBlobUrl) {
        URL.revokeObjectURL(videoBlobUrl); // Clean up old blob URLs to avoid memory leaks
      }
    };
  }, [videoBlobUrl, videoUrl]);
  useEffect(() => {
    const determineContentType = (url: string) => {
      if (!url) return 'website';
      if (url.includes('youtube.com') || url.includes('youtu.be')) {
        return 'youtube';
      } else if (url.includes('vimeo')) {
        return 'youtube';
      } else if (url.includes('.mp3')) {
        return 'mp3';
      } else if (url.includes('.opus')) {
        return 'mp3';
      } else if (url.includes('code')) {
        return 'code';
      } else if (url.includes('.pdf')) {
        return 'imbedPdf';
      } else if (url.includes('.mp4')) {
        return 'mp4';
      } else {
        return 'website';
      }
    };
    const type = determineContentType(videoUrl);
    setContentType(type);
    if (type === 'mp4') {
      setIsMp4(type === 'mp4');
    } else if (type === 'imbedPdf' || type === 'mp3' || type === 'code') {
      if (!localUrl) {
        dispatch(fetchBlob(videoUrl)); // Use fetchBlob for PDFs
      }
    }
  }, [videoUrl, localUrl, videoData]);

  const renderContent = () => {
    let content;
    if (blobLoading) {
      return <LoadingDots
        isEnabled={true}
        messages={[
          "Uploading once, streaming lightning-fast forever.",
          "Just one upload and you'll be streaming in no time!",
          "One-time upload, endless playback speed.",
          "This is the only time you’ll have to wait – it’ll be instant next time!",
          "Just a quick upload, and the video will be as fast as flipping a switch!",
          "Patience for now, blazing speed awaits future views!",
          "Upload once, and from now on, it’s smooth sailing.",
          "Your video is uploading – next time, it will feel like light speed.",
          "A short wait now, lightning-quick access later!",
          "This upload sets the stage for instant streaming every time after.",
          "One-time upload, then it's like a video on demand.",
          "Uploading now, but after this, it’s all about speed!",
          "The wait is temporary; next time will be lightning fast!",
          "Hang tight during this upload, future streams will be seamless.",
          "Just a single upload, and your video will be faster than ever!",
          "Uploading now, streaming instantly next time – it’s worth the wait.",
          "One upload now, and it's fast-forward to quick access later!",
          "Your video is uploading; future views will be instant as a flash.",
          "It takes one upload for speedier, faster streaming next time!",
          "Uploading once, and your video will stream as fast as a bolt of lightning from here on."
        ]}
      />
    }
    switch (contentType) {
      case 'imbedPdf':
        content = (localUrl && <PDFViewer videoData={videoData} fileUrl={localUrl} startPage={videoData.startTime} endPage={videoData.endTime} />);
        break;
      case 'pdf':
        content = (
          <iframe
            key={localUrl}
            src={`${localUrl}#page=${videoData.startTime}&zoom=page-width`}
            onLoad={() => console.log('Iframe loaded successfully')}
            onError={() => console.log('Error loading the iframe')}
            frameBorder="0"
            allowFullScreen
            style={{ width: '100%', height: '570px' }}
          ></iframe>

        );
        break;
      case 'youtube':
        content = (<ReactPlayerComponent videoUrl={videoUrl} startTime={videoData.startTime} frameTime={videoData.frameTime} endTime={videoData.endTime} onTakeTest={onTakeTest} movieList={movieList} videoData={videoData} onVideoComplete={() => onVideoComplete(videoData.movieId, videoData.id)} />);
        break;
      case 'mp3':
        content = localUrl && (<ReactPlayerComponent videoUrl={localUrl} startTime={videoData.startTime} frameTime={videoData.frameTime} endTime={videoData.endTime} onTakeTest={onTakeTest} movieList={movieList} videoData={videoData} onVideoComplete={() => onVideoComplete(videoData.movieId, videoData.id)} />);
        break;
      case 'code':
        content = localUrl && (<CodeViewer blobUrl={localUrl} />);
        break;
      case 'mp4':
        content = videoUrl && (
          <ReactStreamingComponent
            key={videoBlobUrl}
            videoUrl={videoBlobUrl ? videoBlobUrl : videoUrl}
            startTime={videoData.startTime}
            frameTime={videoData.frameTime}
            endTime={videoData.endTime}
            onTakeTest={onTakeTest}
            movieList={movieList}
            videoData={videoData}
            onVideoComplete={() => onVideoComplete(videoData.movieId, videoData.id)}
          />

        )
        break;
      // case 'mp3':
      //   content = (
      //     <audio controls style={{ width: '100%' }}>
      //       <source src={videoUrl} type="audio/mpeg" />
      //       Your browser does not support the audio element.
      //     </audio>
      //   );
      //   break;
      case 'website':
        content = (
          <iframe
            src={videoUrl}
            frameBorder="0"
            allowFullScreen
            style={{ width: '100%', height: '100%' }}
          ></iframe>
        );
        break;
      default:
        content = <Typography>Unsupported media type</Typography>;
    }
    return content;
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ height: '55px', backgroundColor: theme.palette.background.paper, marginTop: '10px', textAlign: 'center' }}>
        <Typography sx={{ fontSize: "16px", paddingTop: '10px' }}> <span style={{ fontWeight: 'bold' }}>{videoData.movieName}:</span> <span style={{ fontStyle: 'italic' }}>{videoData.title}</span></Typography>
      </Box>
      <Box sx={{ width: "100%", marginRight: '30px' }}>
        <Divider />
      </Box>
      {/* {isMp4 && (
        <VideoDownloader videoUrl={videoUrl} onDownloadComplete={setVideoBlobUrl} />
      )} */}

      <Box sx={{ overflow: 'hidden', backgroundColor: theme.palette.background.paper }} >
        {renderContent()}
      </Box>

    </Box>
  );
};

export default MainVideo;
